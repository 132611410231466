import { AbiItem } from 'web3-utils'

const RegistrarAbi: AbiItem[] = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'registrationFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'monthlyFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'minimumExtensionFee',
        type: 'uint256',
      },
    ],
    name: 'FeesUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'NewRegistration',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'RegistrationCompleted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'RegistrationExtended',
    type: 'event',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'subnetId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'blockchainId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'vmId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'rpcUrl',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressSubnet',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'tokenAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'wrappedTokenAddressSubnet',
            type: 'address',
          },
        ],
        internalType: 'struct Registrar.CompletedRegistration',
        name: 'cr',
        type: 'tuple',
      },
    ],
    name: 'complete',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'completedRegistrations',
    outputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'subnetId',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'blockchainId',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'vmId',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'rpcUrl',
        type: 'string',
      },
      {
        internalType: 'address',
        name: 'bridgeAddressCChain',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'bridgeAddressSubnet',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddressCChain',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'wrappedTokenAddressSubnet',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'extendSubnet',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'getCompletedRegistration',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'subnetId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'blockchainId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'vmId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'rpcUrl',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressSubnet',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'tokenAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'wrappedTokenAddressSubnet',
            type: 'address',
          },
        ],
        internalType: 'struct Registrar.CompletedRegistration',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'getCompletedRegistrationForUserAtIndex',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'subnetId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'blockchainId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'vmId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'rpcUrl',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressSubnet',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'tokenAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'wrappedTokenAddressSubnet',
            type: 'address',
          },
        ],
        internalType: 'struct Registrar.CompletedRegistration',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'symbol',
        type: 'string',
      },
    ],
    name: 'getDataForSymbol',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'creator',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'chainId',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'enableBridge',
            type: 'bool',
          },
          {
            internalType: 'uint8',
            name: 'bridgePercent',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'goodUntil',
            type: 'uint256',
          },
          {
            internalType: 'enum Registrar.RegistrationStatus',
            name: 'status',
            type: 'uint8',
          },
        ],
        internalType: 'struct Registrar.Registration',
        name: '',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'subnetId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'blockchainId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'vmId',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'rpcUrl',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'bridgeAddressSubnet',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'tokenAddressCChain',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'wrappedTokenAddressSubnet',
            type: 'address',
          },
        ],
        internalType: 'struct Registrar.CompletedRegistration',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'getNumRegistrationsForUser',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'getRegistration',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'creator',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'chainId',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'enableBridge',
            type: 'bool',
          },
          {
            internalType: 'uint8',
            name: 'bridgePercent',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'goodUntil',
            type: 'uint256',
          },
          {
            internalType: 'enum Registrar.RegistrationStatus',
            name: 'status',
            type: 'uint8',
          },
        ],
        internalType: 'struct Registrar.Registration',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'getRegistrationForUserAtIndex',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'creator',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'chainId',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'enableBridge',
            type: 'bool',
          },
          {
            internalType: 'uint8',
            name: 'bridgePercent',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'goodUntil',
            type: 'uint256',
          },
          {
            internalType: 'enum Registrar.RegistrationStatus',
            name: 'status',
            type: 'uint8',
          },
        ],
        internalType: 'struct Registrar.Registration',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'minimumExtensionFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'monthlyFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'symbol',
        type: 'string',
      },
      {
        internalType: 'uint256',
        name: 'totalSupply',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'chainId',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'enableBridge',
        type: 'bool',
      },
      {
        internalType: 'uint8',
        name: 'bridgePercent',
        type: 'uint8',
      },
    ],
    name: 'registerNewSubnet',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'registrationFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'registrations',
    outputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'symbol',
        type: 'string',
      },
      {
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'totalSupply',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'chainId',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'enableBridge',
        type: 'bool',
      },
      {
        internalType: 'uint8',
        name: 'bridgePercent',
        type: 'uint8',
      },
      {
        internalType: 'uint256',
        name: 'goodUntil',
        type: 'uint256',
      },
      {
        internalType: 'enum Registrar.RegistrationStatus',
        name: 'status',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'secondsForValue',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'symbol',
        type: 'string',
      },
    ],
    name: 'symbolExists',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    name: 'symbolMapping',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalRegistrations',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_registrationFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_monthlyFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_minimumExtensionFee',
        type: 'uint256',
      },
    ],
    name: 'updateFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'userRegistrations',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export { RegistrarAbi }
