import { BigNumber } from 'ethers'
import { CompletedRegistration } from './CompletedRegistration'

interface IRegistration {
  chainId: string
  bridgePercent: number
  creator: string
  enableBridge: boolean
  symbol: string
  goodUntil: BigNumber | number
  id: BigNumber | string
  name: string
  status: number
  totalSupply: BigNumber | string
}

class Registration implements IRegistration {
  public chainId: string
  public bridgePercent: number
  public creator: string
  public enableBridge: boolean
  public symbol: string
  public goodUntil: number
  public id: string
  public name: string
  public status: number
  public totalSupply: string
  public collapse: boolean
  public completed?: CompletedRegistration

  constructor(registration: IRegistration) {
    this.chainId = registration.chainId
    this.bridgePercent = registration.bridgePercent
    this.creator = registration.creator
    this.enableBridge = registration.enableBridge
    this.symbol = registration.symbol
    this.goodUntil = BigNumber.from(registration.goodUntil).mul(1000).toNumber()
    this.id = BigNumber.from(registration.id).toString()
    this.name = registration.name
    this.status = registration.status
    this.totalSupply = BigNumber.from(registration.totalSupply).toString()
    this.collapse = false
  }
}

export { Registration }
