import React, { useMemo, useState, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import { AppConfig, AppConfigContext } from './useAppConfig'
import { SourcifySource } from './url'
import { useRuntime, RuntimeContext } from './useRuntime'
import { ConnectionStatus } from './types'
import ConnectionErrorPanel from './ConnectionErrorPanel'
import WarningHeader from './WarningHeader'
import Footer from './Footer'

const Main: React.FC = () => {
  const runtime = useRuntime()
  const [sourcifySource, setSourcifySource] = useState<SourcifySource>(
    SourcifySource.IPFS_IPNS
  )
  const appConfig = useMemo((): AppConfig => {
    return {
      sourcifySource,
      setSourcifySource,
    }
  }, [sourcifySource, setSourcifySource])

  return (
    <Suspense fallback={null}>
      {runtime.connStatus !== ConnectionStatus.CONNECTED ? (
        <ConnectionErrorPanel
          connStatus={runtime.connStatus}
          config={runtime.config}
        />
      ) : (
        <RuntimeContext.Provider value={runtime}>
          <AppConfigContext.Provider value={appConfig}>
            {/* TODO: Hacking version at the moment. Revisit later */}
            {window.location.href.includes('/explorer') && <WarningHeader />}
            <Header />
            <Outlet />
            {/* TODO: Hacking version at the moment. Revisit later */}
            {window.location.href.includes('/explorer') && <Footer />}
          </AppConfigContext.Provider>
        </RuntimeContext.Provider>
      )}
    </Suspense>
  )
}

export default Main
