import React from 'react'
import Box from '@mui/material/Box'

import snowBackground from '../images/background2-min.jpeg'
import snowGenesisHero from '../images/snowgenesis-hero4.png'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { NavigationItem } from '../components/NavigationItem'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import { Card } from '../components/Card'
import Badge from '@mui/material/Badge'

function Home() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          overflowX: 'hidden',
          width: '100%',
          minHeight: '100vh',
          position: 'fixed',
          backgroundImage: `url(${snowBackground})`,
          filter: 'brightness(0.95)',
          zIndex: -1,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <Box
        sx={{
          overflowX: 'hidden',
          background: 'white',
          filter: 'brightness(0.95)',
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            width: '1440px',
            margin: '0 auto',
            height: '4rem',
            display: 'flex',
            alignItems: 'center',
            padding: '0 2rem',
          }}
        >
          <AcUnitIcon
            sx={{
              fontSize: '2rem',
              color: '#4379FF',
              mr: '0.5rem',
            }}
          />
          <Typography
            fontSize="1.5rem"
            color={'#4379FF'}
            sx={{ mr: '2rem', fontWeight: 'bold' }}
          >
            SnowGenesis
          </Typography>
          {matches && (
            <>
              <NavigationItem
                color="#4379FF"
                to={'/subnets'}
                text={'Subnets'}
              />
              <Badge badgeContent={'Soon'} color="primary">
                <NavigationItem
                  disabled
                  color="gray"
                  to={'/bridge'}
                  text={'Bridge'}
                />
              </Badge>
              {/*<NavigationItem disabled text={'Marketplace'} />*/}
              <Badge badgeContent={'Soon'} color="primary">
                <NavigationItem
                  disabled
                  color="gray"
                  to={'/explorer'}
                  text={'Explorer'}
                />
              </Badge>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '1440px',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: matches ? 'space-between' : 'center',
          pb: '1rem',
        }}
      >
        <Box>
          <Box
            color={'#4379FF'}
            fontWeight={'bold'}
            padding={matches ? '0 4rem' : '4rem 2rem 4rem 2rem'}
            // minHeight={matches ? 'initial' : '100vh'}
          >
            <Typography
              sx={{ display: 'inline' }}
              component={'h1'}
              fontSize={matches ? (theme) => theme.typography.h1.fontSize : 48}
              lineHeight={1.2}
              fontWeight={'bold'}
              color={'white'}
            >
              The fastest way to spin up managed blockchains on{' '}
            </Typography>
            <Box
              sx={{
                display: 'inline',
                background: 'white',
              }}
              component={'h1'}
              fontSize={matches ? (theme) => theme.typography.h1.fontSize : 48}
              lineHeight={1.8}
              fontWeight={'bold'}
              color={'#E84142'}
            >
              Avalanche
            </Box>
          </Box>
        </Box>
        {matches && (
          <Box
            sx={{
              width: '50%',
              height: '50%',
              padding: '2rem 2rem 0 0',
            }}
          >
            <Box component="img" src={snowGenesisHero} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          padding: '4rem 0',
          filter: 'brightness(100%)',
          background: 'white',
        }}
      >
        <Typography
          component={'h2'}
          variant={'h3'}
          fontSize={(theme) => theme.typography.h2.fontSize}
          sx={{
            color: '#1f2226',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Products
        </Typography>
        <Box
          sx={{
            margin: matches ? '2rem auto' : '2rem auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            maxWidth: '1440px',
            textAlign: 'center',
            padding: matches ? '2rem' : 0,
            color: (theme) => theme.palette.text.secondary,
            lineHeight: '60px',
            flexDirection: matches ? 'row' : 'column',
          }}
        >
          <Card
            title="Subnets"
            description={
              'Quickly spin up an EVM-compatible blockchain in an Avalanche Subnet. SnowGenesis will run the validation infrastructure, so you can focus on your product.'
            }
            text={'Learn More'}
            link={'/subnets'}
          />
          <Card
            title="Bridge"
            description={
              'Allow users to frictionlessly onboard by bridging tokens from the C-Chain to your blockchain for seamless adoption.'
            }
            disabled
            text={'Coming Soon'}
            link={'/bridge'}
          />
          <Card
            title="Block Explorer"
            description={
              'Every blockchain comes with a built-in block explorer (like snowtrace) to give visibility into blocks and transactions.'
            }
            text={'Coming Soon'}
            disabled
            link={'/explorer'}
          />
        </Box>
      </Box>
      {/* Footer */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '1440px',
            margin: 'auto',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: (theme) => theme.palette.text.secondary,
            lineHeight: '60px',
            flexDirection: matches ? 'row' : 'column',
            padding: matches ? '0 2rem' : '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: matches ? 'row' : 'column',
              padding: '1rem 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <AcUnitIcon
                sx={{
                  fontSize: '2rem',
                  color: 'white',
                  mr: '0.5rem',
                }}
              />
              <Typography
                fontSize="1.5rem"
                color={'white'}
                sx={{ fontWeight: 'bold' }}
              >
                SnowGenesis
              </Typography>
            </Box>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:hello@snowgenesis.com"
            >
              <Typography
                fontSize="1rem"
                color={'white'}
                sx={{ fontWeight: 'bold' }}
              >
                hello@snowgenesis.com
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Home
