import React, { useContext } from 'react'
import { RuntimeContext } from './useRuntime'

const Logo: React.FC = () => {
  const { config } = useContext(RuntimeContext)

  if (!config) {
    return null
  }

  return (
    <div className="mx-auto text-4xl md:text-6xl text-link-blue font-title font-bold cursor-default flex items-center text-center">
      <span>{config.registration?.name} Scan</span>
    </div>
  )
}

export default React.memo(Logo)
