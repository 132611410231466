import React from 'react'
import Box from '@mui/material/Box'
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Card = ({
  title,
  disabled,
  description,
  text,
  link,
}: {
  title: string
  disabled?: boolean
  description?: string
  text: string
  link: string
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const matchesLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2rem',
        maxWidth: matches ? '33%' : '85%',
        minWidth: matches ? '33%' : '85%',
        minHeight: '300px',
        zIndex: 3,
        border: matches ? 'none' : '1px solid lightgray',
        boxShadow: 3,
      }}
    >
      <Typography
        component="h2"
        variant={'h4'}
        fontSize={(theme) => theme.typography.h4.fontSize}
        sx={{ my: '0rem', fontWeight: 'bold' }}
        color={'#4379FF'}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          my: '2rem',
          fontSize: '1.15rem',
          textAlign: 'left',
          color: '#1f2226',
          minHeight: matchesLarge ? '8rem' : matches ? '12rem' : '0rem',
        }}
      >
        {description}
      </Typography>
      <Button
        disabled={disabled}
        variant={'outlined'}
        onClick={() => navigate(link)}
      >
        {text}
      </Button>
    </Box>
  )
}

export { Card }
