import React, { useState, useEffect } from 'react'

type TimestampAgeProps = {
  now?: number | undefined
  timestamp: number
}

const TimestampAge: React.FC<TimestampAgeProps> = ({ now, timestamp }) => {
  const [desc, setDesc] = useState('')

  const updateTime = () => {
    now = Date.now() / 1000
    let diff = now - timestamp

    let description = ''
    if (diff <= 1) {
      description = '1 sec ago'
    } else if (diff < 60) {
      description = `${Math.trunc(diff)} secs ago`
    } else {
      const days = Math.trunc(diff / 86400)
      diff %= 86400
      const hours = Math.trunc(diff / 3600)
      diff %= 3600
      const mins = Math.trunc(diff / 60)

      description = ''
      if (days > 0) {
        description += `${days} ${days === 1 ? 'day' : 'days'} `
      }
      if (hours > 0) {
        description += `${hours} ${hours === 1 ? 'hr' : 'hrs'} `
      }
      if (days === 0 && mins > 0) {
        description += `${mins} ${mins === 1 ? 'min' : 'mins'} `
      }
      description += 'ago'
    }

    setDesc(description)
  }

  useEffect(() => {
    updateTime()
    const timer = setInterval(() => {
      updateTime()
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <span className="truncate" title={desc}>
      {desc}
    </span>
  )
}

export default React.memo(TimestampAge)
