import {
  ChangeEventHandler,
  FormEventHandler,
  RefObject,
  useRef,
  useState,
} from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router'
import { JsonRpcProvider, TransactionResponse } from '@ethersproject/providers'
import { isAddress } from '@ethersproject/address'
import { isHexString } from '@ethersproject/bytes'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { PAGE_SIZE } from '../params'
import { ProcessedTransaction, TransactionChunk } from '../types'
import { BigNumber } from 'ethers'

export class SearchController {
  private txs: ProcessedTransaction[]

  private pageStart: number

  private pageEnd: number

  private constructor(
    readonly address: string,
    txs: ProcessedTransaction[],
    readonly isFirst: boolean,
    readonly isLast: boolean,
    boundToStart: boolean
  ) {
    this.txs = txs
    if (boundToStart) {
      this.pageStart = 0
      this.pageEnd = Math.min(txs.length, PAGE_SIZE)
    } else {
      this.pageEnd = txs.length
      this.pageStart = Math.max(0, txs.length - PAGE_SIZE)
    }
  }

  private static rawToProcessed = (provider: JsonRpcProvider, _rawRes: any) => {
    const _res: TransactionResponse[] = _rawRes.txs.map((t: any) =>
      provider.formatter.transactionResponse(t)
    )

    return {
      txs: _res.map((t, i): ProcessedTransaction => {
        const _rawReceipt = _rawRes.receipts[i]
        const _receipt = provider.formatter.receipt(_rawReceipt)
        return {
          blockNumber: t.blockNumber!,
          timestamp: provider.formatter.number(_rawReceipt.timestamp),
          idx: _receipt.transactionIndex,
          hash: t.hash,
          from: t.from,
          to: t.to ?? null,
          createdContractAddress: _receipt.contractAddress,
          value: t.value,
          fee: _receipt.gasUsed.mul(t.gasPrice!),
          gasPrice: t.gasPrice!,
          data: t.data,
          status: _receipt.status!,
        }
      }),
      firstPage: _rawRes.firstPage,
      lastPage: _rawRes.lastPage,
    }
  }

  // first transaction hash for the address
  // 25

  private static async readBackPage(
    provider: JsonRpcProvider,
    address: string,
    baseBlock: number
  ): Promise<TransactionChunk> {
    const blockNumberToStart = baseBlock
      ? baseBlock
      : await provider.send('eth_blockNumber', [])
    const addressLowerCased = address.toLowerCase()
    const latestBlockNumber = BigNumber.from(blockNumberToStart).toNumber()
    const blocks = await Promise.all(
      new Array(BigNumber.from(blockNumberToStart).toNumber())
        .fill(null)
        .map((el: any, i: number) => {
          return provider.send('eth_getBlockByNumber', [
            `0x${(latestBlockNumber - i).toString(16)}`,
            true,
          ])
        })
    )
    const timeStampByBlock = blocks.reduce((prev, curr) => {
      prev[BigNumber.from(curr.number).toNumber()] = curr.timestamp
      return prev
    }, {})
    let transactions = blocks.reduce((prev, curr) => {
      const txs = curr.transactions.filter(
        (el: any) =>
          el.from?.toLowerCase() === addressLowerCased ||
          el.to?.toLowerCase() === addressLowerCased
      )
      prev = [...prev, ...txs]
      return prev
    }, [])
    let receipts = await Promise.all(
      transactions.map((el: any) =>
        provider.send('eth_getTransactionReceipt', [el.hash])
      )
    )
    receipts = receipts.map((el) => {
      return {
        ...el,
        timestamp: timeStampByBlock[BigNumber.from(el.blockNumber).toNumber()],
      }
    })
    const slice = transactions.slice(0, 25)
    // const _rawRes = await provider.send("ots_searchTransactionsBefore", [
    //   address,
    //   baseBlock,
    //   PAGE_SIZE,
    // ]);
    return this.rawToProcessed(provider, {
      txs: transactions,
      receipts,
      firstPage:
        baseBlock === 0 ? true : transactions.length - slice.length > 0,
      lastPage: slice.length < 25,
    })
  }

  private static async readForwardPage(
    provider: JsonRpcProvider,
    address: string,
    baseBlock: number
  ): Promise<TransactionChunk> {
    const latestBlockNumber = BigNumber.from(
      await provider.send('eth_blockNumber', [])
    ).toNumber()
    const addressLowerCased = address.toLowerCase()
    const blocks = await Promise.all(
      new Array(BigNumber.from(latestBlockNumber).toNumber())
        .fill(null)
        .map((el: any, i: number) => {
          return provider.send('eth_getBlockByNumber', [
            `0x${(latestBlockNumber - i).toString(16)}`,
            true,
          ])
        })
    )
    const timeStampByBlock = blocks.reduce((prev, curr) => {
      prev[BigNumber.from(curr.number).toNumber()] = curr.timestamp
      return prev
    }, {})
    let transactions = blocks.reduce((prev, curr) => {
      const txs = curr.transactions.filter(
        (el: any) =>
          el.from?.toLowerCase() === addressLowerCased ||
          el.to?.toLowerCase() === addressLowerCased
      )
      prev = [...prev, ...txs]
      return prev
    }, [])
    let receipts = await Promise.all(
      transactions.map((el: any) =>
        provider.send('eth_getTransactionReceipt', [el.hash])
      )
    )
    receipts = receipts.map((el) => {
      return {
        ...el,
        timestamp: timeStampByBlock[BigNumber.from(el.blockNumber).toNumber()],
      }
    })

    const slice = transactions.slice(0, -baseBlock)

    return this.rawToProcessed(provider, {
      txs: transactions,
      receipts,
      firstPage:
        baseBlock === 0 ? true : transactions.length - slice.length > 0,
      lastPage: transactions.length < 25,
    })
  }

  static async firstPage(
    provider: JsonRpcProvider,
    address: string
  ): Promise<SearchController> {
    const newTxs = await SearchController.readBackPage(provider, address, 0)
    return new SearchController(
      address,
      newTxs.txs,
      newTxs.firstPage,
      newTxs.lastPage,
      true
    )
  }

  static async middlePage(
    provider: JsonRpcProvider,
    address: string,
    hash: string,
    next: boolean
  ): Promise<SearchController> {
    const tx = await provider.getTransaction(hash)
    const newTxs = next
      ? await SearchController.readBackPage(provider, address, tx.blockNumber!)
      : await SearchController.readForwardPage(
          provider,
          address,
          tx.blockNumber!
        )
    return new SearchController(
      address,
      newTxs.txs,
      newTxs.firstPage,
      newTxs.lastPage,
      next
    )
  }

  static async lastPage(
    provider: JsonRpcProvider,
    address: string
  ): Promise<SearchController> {
    const newTxs = await SearchController.readForwardPage(provider, address, 0)
    return new SearchController(
      address,
      newTxs.txs,
      newTxs.firstPage,
      newTxs.lastPage,
      false
    )
  }

  getPage(): ProcessedTransaction[] {
    return this.txs.slice(this.pageStart, this.pageEnd)
  }

  async prevPage(
    provider: JsonRpcProvider,
    hash: string
  ): Promise<SearchController> {
    // Already on this page
    if (this.txs[this.pageEnd - 1].hash === hash) {
      return this
    }

    if (this.txs[this.pageStart].hash === hash) {
      debugger
      // last transaction should be the header
      const overflowPage = this.txs.slice(0, this.pageStart)
      const baseBlock = this.txs[0].blockNumber
      const prevPage = await SearchController.readForwardPage(
        provider,
        this.address,
        baseBlock
      )
      return new SearchController(
        this.address,
        prevPage.txs.concat(overflowPage),
        prevPage.firstPage,
        prevPage.lastPage,
        true
      )
    }

    return this
  }

  async nextPage(
    provider: JsonRpcProvider,
    hash: string
  ): Promise<SearchController> {
    // Already on this page
    if (this.txs[this.pageStart].hash === hash) {
      return this
    }

    if (this.txs[this.pageEnd - 1].hash === hash) {
      const overflowPage = this.txs.slice(this.pageEnd)
      const baseBlock = overflowPage[0]?.blockNumber
      const nextPage = await SearchController.readBackPage(
        provider,
        this.address,
        baseBlock
      )
      return new SearchController(
        this.address,
        overflowPage,
        nextPage.firstPage,
        nextPage.lastPage,
        true
      )
    }

    return this
  }
}

const doSearch = async (
  q: string,
  navigate: NavigateFunction,
  symbol: string
) => {
  // Cleanup
  q = q.trim()

  let maybeAddress = q
  let maybeIndex = ''
  const sepIndex = q.lastIndexOf(':')
  if (sepIndex !== -1) {
    maybeAddress = q.substring(0, sepIndex)
    maybeIndex = q.substring(sepIndex + 1)
  }

  // Plain address?
  if (isAddress(maybeAddress)) {
    navigate(`/explorer/${symbol}/address/${maybeAddress}`)
    return
  }

  // Tx hash?
  if (isHexString(q, 32)) {
    navigate(`/explorer/${symbol}/tx/${q}`, { replace: true })
    return
  }

  // Block number?
  const blockNumber = parseInt(q)
  if (!isNaN(blockNumber)) {
    navigate(`/explorer/${symbol}/block/${blockNumber}`, { replace: true })
    return
  }

  // Assume it is an ENS name
  navigate(
    `/explorer/${symbol}/address/${maybeAddress}${
      maybeIndex !== '' ? `?nonce=${maybeIndex}` : ''
    }`,
    { replace: true }
  )
}

export const useGenericSearch = (): [
  RefObject<HTMLInputElement>,
  ChangeEventHandler<HTMLInputElement>,
  FormEventHandler<HTMLFormElement>
] => {
  const [searchString, setSearchString] = useState<string>('')
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const navigate = useNavigate()
  const { symbol } = useParams()

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchTerm = e.target.value.trim()
    setCanSubmit(searchTerm.length > 0)
    setSearchString(searchTerm)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (!canSubmit) {
      return
    }

    if (searchRef.current) {
      searchRef.current.value = ''
    }
    doSearch(searchString, navigate, symbol!)
  }

  const searchRef = useRef<HTMLInputElement>(null)
  useKeyboardShortcut(['/'], () => {
    searchRef.current?.focus()
  })

  return [searchRef, handleChange, handleSubmit]
}
