import React from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import Checkbox from '@mui/material/Checkbox'
import { formatEther } from '@ethersproject/units'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import CircleIcon from '@mui/icons-material/Circle'
import LoadingButton from '@mui/lab/LoadingButton'

import { Navigation } from '../components/Navigation'
import { Container } from '../components/Container'
import { FormContainer } from '../components/FormContainer'
import { Title } from '../components/Title'
import { Registrar } from '../apis/registrarContract'
import { Registration } from '../models/Registration'

import { numberWithCommas } from '../utils'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import { PageContainer } from '../components/PageContainer'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from '../contexts/SnackbarProvider'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import { useListener } from '../hooks/listeners'

const Profile = () => {
  const { refreshed } = useListener()
  const { active, account, library } = useWeb3React()
  const [loading, setLoading] = React.useState(false)
  const { snackbarProps, setSnackbarProps } = useSnackbar()
  const [minimumExtensionFee, setMinimumExtensionFee] =
    React.useState<string>('')
  const [defaultExtensionUntil, setDefaultExtensionUntil] = React.useState(0)
  const [registrar, setRegistrar] = React.useState<Registrar>()
  const [registrations, setRegistrations] = React.useState<Registration[]>([])
  const [values, setValues] = React.useState<{
    [key: string]: {
      value: string
      seconds: number
    }
  }>({})
  const [pageLoading, setPageLoading] = React.useState(true)
  const navigate = useNavigate()

  const handleCollapse = (id: BigNumber | string) => (e: any) => {
    const slice = registrations.slice(0)
    const registration = slice.find((r: Registration) => r.id === id)!
    registration.collapse = !registration.collapse
    setRegistrations(slice)
  }

  React.useEffect(() => {
    setRegistrations([])
    if (!!active && !!library && !!account) {
      const re = new Registrar(library.getSigner(), account)
      const asyncCall = async () => {
        setPageLoading(true)
        try {
          const minExtensionFee = formatEther(
            BigNumber.from(await re.getMinimumExtensionFee()).toString()
          )
          setMinimumExtensionFee(minExtensionFee)
          setDefaultExtensionUntil(await re.getSecondsForValue(minExtensionFee))
          setRegistrations(await re.getAllRegistrationForUser())
          setRegistrar(re)
        } catch (err) {}

        setPageLoading(false)
      }

      asyncCall()
    }
  }, [active, library, account, refreshed])

  return (
    <Navigation>
      <>
        {pageLoading && (
          <Box
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: '2rem',
            }}
          >
            <CircularProgress size="5rem" color="secondary" />
          </Box>
        )}
        <PageContainer>
          {registrations.length ? (
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Title
                  extraMargin
                  title="Profile"
                  subtitle="This page displays all the subnets owned by you with its configuration."
                />
                {/* TODO: This is duplicate of the useEffect call. Clean this up after. */}
                <IconButton
                  onClick={async () => {
                    setRegistrations([])
                    const re = new Registrar(library.getSigner(), account!)
                    const asyncCall = async () => {
                      setPageLoading(true)
                      try {
                        const minExtensionFee = formatEther(
                          BigNumber.from(
                            await re.getMinimumExtensionFee()
                          ).toString()
                        )
                        setMinimumExtensionFee(minExtensionFee)
                        setDefaultExtensionUntil(
                          await re.getSecondsForValue(minExtensionFee)
                        )
                        setRegistrations(await re.getAllRegistrationForUser())
                        setRegistrar(re)
                      } catch (err) {}

                      setPageLoading(false)
                    }
                    asyncCall()
                  }}
                  edge="end"
                >
                  <RefreshIcon color={'primary'} />
                </IconButton>
              </Box>
              {registrations?.map((registration) => {
                return (
                  <Container key={registration.name + registration.id}>
                    <Box
                      sx={{
                        mb: '2rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Title
                        title={`⛓ ${registration.name} (${registration.symbol})`}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontWeight="bold" marginRight={'0.5rem'}>
                          {registration.status ? 'Complete' : 'Pending'}
                        </Typography>
                        <CircleIcon
                          color={registration.status ? 'success' : 'warning'}
                        />
                        <IconButton onClick={handleCollapse(registration.id)}>
                          {registration.collapse ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowRightIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    {!registration.completed && (
                      <>
                        <Alert severity="warning" sx={{ my: '1rem' }}>
                          Creating Subnet and setting up blockchain... this page
                          will refresh once your subnet is ready.
                        </Alert>
                      </>
                    )}
                    <Collapse in={registration.collapse}>
                      <Container>
                        <Title title="Subnet and Blockchain Configuration" />
                        <FormContainer>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={registration.enableBridge}
                                  disabled
                                />
                              }
                              label="Bridged Enabled"
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel htmlFor="chainId">
                              Bridge Percent
                            </InputLabel>
                            <OutlinedInput
                              name="bridgePercent"
                              label="Bridge Percent"
                              id="bridgePercent"
                              value={`${registration.bridgePercent}%`}
                              disabled
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel htmlFor="chainId">Chain ID</InputLabel>
                            <OutlinedInput
                              name="chainId"
                              type="number"
                              label="Balance"
                              id="chainId"
                              value={registration.chainId}
                              disabled
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel htmlFor="chainId">
                              Total Supply
                            </InputLabel>
                            <OutlinedInput
                              name="totalSupply"
                              label="Total Supply"
                              id="totalSupply"
                              value={numberWithCommas(
                                BigNumber.from(
                                  registration.totalSupply
                                ).toString()
                              )}
                              disabled
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel htmlFor="goodUntil">
                              Expiration Date
                            </InputLabel>
                            <OutlinedInput
                              name="goodUntil"
                              label="Expiration Date"
                              id="goodUntil"
                              disabled
                              value={new Date(registration.goodUntil)}
                            />
                          </FormControl>
                        </FormContainer>
                      </Container>
                      <Container>
                        <Title title={`Deadline Extension`} />
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel htmlFor="value">Value</InputLabel>
                            <OutlinedInput
                              name={`value-${registration.id}`}
                              label="Value"
                              id="value"
                              value={
                                values[registration.id]?.value ||
                                minimumExtensionFee
                              }
                              onBlur={async () => {
                                const seconds =
                                  await registrar?.getSecondsForValue(
                                    values[registration.id]?.value
                                  )!
                                setValues({
                                  ...values,
                                  [registration.id]: {
                                    ...values[registration.id],
                                    seconds,
                                  },
                                })
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  [registration.id]: {
                                    ...values[registration.id],
                                    value: e.target.value,
                                  },
                                })
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={async () => {
                                      const seconds =
                                        await registrar?.getSecondsForValue(
                                          values[registration.id]?.value ||
                                            minimumExtensionFee
                                        )!
                                      setValues({
                                        ...values,
                                        [registration.id]: {
                                          ...values[registration.id],
                                          seconds,
                                        },
                                      })
                                    }}
                                    edge="end"
                                  >
                                    <RefreshIcon color={'primary'} />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText>
                              This will extend your time until{' '}
                              {values[registration.id]?.seconds
                                ? new Date(
                                    values[registration.id].seconds +
                                      registration.goodUntil
                                  ).toString()
                                : new Date(
                                    defaultExtensionUntil +
                                      registration.goodUntil
                                  ).toString()}
                            </FormHelperText>
                          </FormControl>
                        </FormContainer>
                        <LoadingButton
                          loading={loading}
                          variant="contained"
                          fullWidth
                          sx={{ mt: 2 }}
                          onClick={async () => {
                            try {
                              setLoading(true)
                              const response = await registrar?.extendSubnet(
                                BigNumber.from(registration.id).toHexString(),
                                values[registration.id]?.value ||
                                  minimumExtensionFee
                              )
                              setSnackbarProps({
                                ...snackbarProps,
                                open: true,
                                severity: 'success',
                                message: `Transaction Succeeded. Hash ${response.transactionHash}`,
                              })
                              setLoading(false)
                            } catch (err: any) {
                              setSnackbarProps({
                                ...snackbarProps,
                                open: true,
                                severity: 'error',
                                message:
                                  err.code === -32603
                                    ? err.data.message
                                    : err.message,
                              })
                            } finally {
                              setLoading(false)
                            }
                          }}
                        >
                          Extend
                        </LoadingButton>
                      </Container>
                      <Container>
                        <Title title={`Add Network to MetaMask`} />

                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              htmlFor={`networkName-${registration.symbol}`}
                            >
                              Network Name
                            </InputLabel>
                            <OutlinedInput
                              name={`networkName-${registration.symbol}`}
                              label="Network Name"
                              id={`networkName-${registration.symbol}`}
                              disabled
                              value={registration.name}
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              htmlFor={`networkRPC-${registration.symbol}`}
                            >
                              New RPC URL
                            </InputLabel>
                            <OutlinedInput
                              name={`networkRPC-${registration.symbol}`}
                              label="New RPC URL"
                              id={`networkRPC-${registration.symbol}`}
                              disabled
                              value={registration.completed?.rpcUrl}
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              htmlFor={`chainId-${registration.symbol}`}
                            >
                              Chain ID
                            </InputLabel>
                            <OutlinedInput
                              name={`chainId-${registration.symbol}`}
                              label="Chain ID"
                              id={`chainId-${registration.symbol}`}
                              disabled
                              value={registration.chainId}
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              htmlFor={`networkName-${registration.symbol}`}
                            >
                              Currency Symbol
                            </InputLabel>
                            <OutlinedInput
                              name={`networkName-${registration.symbol}`}
                              label="Currency Symbol"
                              id={`networkName-${registration.symbol}`}
                              disabled
                              value={registration.symbol}
                            />
                          </FormControl>
                        </FormContainer>
                        <FormContainer>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              htmlFor={`networkName-${`${registration.symbol}-snowgenesis.com`}`}
                            >
                              Block Explorer URL
                            </InputLabel>
                            <OutlinedInput
                              name={`networkName-${`${registration.symbol}-snowgenesis.com`}`}
                              label="Block Explorer URL"
                              id={`networkName-${`${registration.symbol}-snowgenesis.com`}`}
                              disabled
                              value={`https://snowgenesis.com/explorer/${registration.symbol.toLowerCase()}/`}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={async () => {
                                      window.open(
                                        `/explorer/${registration.symbol.toLowerCase()}`,
                                        '_blank'
                                      )
                                    }}
                                  >
                                    <LinkIcon color={'primary'} />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </FormContainer>
                        <LoadingButton
                          disabled={!registration.completed}
                          variant="contained"
                          fullWidth
                          sx={{ mt: 2 }}
                          onClick={async () => {
                            // TODO: Fix Metamask Add network
                            const network = {
                              chainId: `0x${Number(
                                registration.chainId
                              ).toString(16)}`,
                              chainName: registration.name,
                              rpcUrls: [registration.completed?.rpcUrl],
                              nativeCurrency: {
                                name: registration.name,
                                symbol: registration.symbol,
                                decimals: 18,
                              },
                              blockExplorerUrls: [
                                `https://snowgenesis.com/explorer/${registration.symbol}`,
                              ],
                            }
                            try {
                              await window?.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [network],
                              })
                            } catch (err: any) {
                              setSnackbarProps({
                                open: true,
                                message: err.message,
                                severity: 'error',
                              })
                            }
                          }}
                        >
                          Add to Metamask
                        </LoadingButton>
                      </Container>
                    </Collapse>
                  </Container>
                )
              })}
            </Container>
          ) : (
            !pageLoading &&
            active && (
              <Alert severity="warning">
                You currently do not have any subnets / blockchains. Go to{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/subnets')}
                >
                  Subnets
                </Link>{' '}
                to create your own subnet / blockchains
              </Alert>
            )
          )}
        </PageContainer>
      </>
    </Navigation>
  )
}

export default Profile
