import { useState, useEffect } from "react";
import { Block } from "@ethersproject/abstract-provider";
import { JsonRpcProvider } from "@ethersproject/providers";

export const useLatestBlock = (provider?: JsonRpcProvider) => {
  const [latestBlock, setLatestBlock] = useState<Block>();

  useEffect(() => {
    if (!provider) {
      return;
    }

    const readLatestBlock = async () => {




      const blockNum = await provider.getBlockNumber();
      const blockNumHex = `0x${blockNum.toString(16)}`

      // baseFeePerGas: "0x306dc4200"
      // blockGasCost: "0x0"
      // difficulty: "0x1"
      // extraData: "0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      // gasLimit: "0x7a1200"
      // gasUsed: "0xe332"
      // hash: "0xa8d7853c487830b640eba598555c86da8d291510f57da6320fd90d49fc3e87e3"
      // logsBloom: "0x00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      // miner: "0x0100000000000000000000000000000000000000"
      // mixHash: "0x0000000000000000000000000000000000000000000000000000000000000000"
      // nonce: "0x0000000000000000"
      // number: "0x18"
      // parentHash: "0x91605eaa36428f9a7b76e508a54f00e302bef998ef11bbde9542e9aed6acf03e"
      // receiptsRoot: "0x3d24f0a7829778167efe75649b4102a6f3d5a96f9a318cf7771e654ca654a378"
      // sha3Uncles: "0x1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347"
      // size: "0x330"
      // stateRoot: "0x10ff2604c1e2da66aaaaee5885af8ec5fc35f6911d75e7b639da00422fcc181a"
      // timestamp: "0x62229720"
      // totalDifficulty: "0x18"
      // transactions: [{…}]
      // transactionsRoot: "0x51202d8cc0b8f1a674b5bdb1bd10b2c1b65808d104136b8c32faaf3c6f27d6d5"
      // uncles: []
      const _raw = await provider.send("eth_getBlockByNumber", [blockNumHex, true]);
      // const header = {
      //   transactions: _raw.transactions,
      //   parentHash: _raw.parentHash,
      //   uncleHash: _raw.sha3Uncles,
      //   coinbase: null,
      //   root: _raw.stateRoot,
      //   txHash: _raw.hash,
      //   hash: _raw.hash,
      //   bloom: _raw.logsBloom,
      //   difficulty: _raw.difficulty,
      //   number: _raw.number,
      //   gasLimit: _raw.gasLimit,
      //   gasUsed: _raw.gasUsed,
      //   time: _raw.timestamp,
      //   extra: _raw.extraData,
      //   mixDigest: _raw.mixHash,
      //   nonce: _raw.nonce,
      //   baseFee: _raw.baseFeePerGas,
      //   eip1599: true,
      // }
      _raw.transactions = _raw.transactions.map((el: any) => el.hash)
      const _block = provider.formatter.block(_raw);
      setLatestBlock(_block);
    };

    provider.on('block', readLatestBlock)
    readLatestBlock();

    return () => {
      provider.removeListener('block', readLatestBlock)
    }

    // const listener = async (blockNumber: number) => {
    //   const _raw = await provider.send("eth_getBlockByNumber", [
    //     blockNumber,
    //   ]);
    //   const _block = provider.formatter.block(_raw);
    //   setLatestBlock(_block);
    // };
    //
    // provider.on("block", listener);
    // return () => {
    //   provider.removeListener("block", listener);
    // };
  }, [provider]);

  return latestBlock;
};

export const useLatestBlockNumber = (provider?: JsonRpcProvider) => {
  const [latestBlock, setLatestBlock] = useState<number>();

  useEffect(() => {
    if (!provider) {
      return;
    }

    const readLatestBlock = async () => {
      const blockNum = await provider.getBlockNumber();
      setLatestBlock(blockNum);
    };
    readLatestBlock();

    const listener = async (blockNumber: number) => {
      setLatestBlock(blockNumber);
    };

    provider.on("block", listener);
    return () => {
      provider.removeListener("block", listener);
    };
  }, [provider]);

  return latestBlock;
};
