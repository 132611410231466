import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import { State } from '../pages/Subnets'
import FormHelperText from '@mui/material/FormHelperText'

const TextField = ({
  id,
  label,
  type = 'text',
  value,
  values,
  setValues,
  handleChange,
  helperText,
}: {
  id: string
  label: string
  type?: string
  value: any
  values: State
  setValues: Function
  handleChange: Function
  helperText?: string
}) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        name={id}
        type={type}
        label={label}
        id={id}
        value={value}
        onChange={handleChange(values, setValues)}
      />
      {helperText && (
        <FormHelperText sx={{ mt: '0.5rem' }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export { TextField }
