import {
  // AVALANCHE_MAINNET,
  // AVALANCHE_TESTNET,
  RAINBOW_KITTIES,
} from '../constants'

interface IAllChains {
  chainId: number
  snowChainId: number
  name: string
  symbol: string
  icon: string
  chainTokenAddress: string
  cChainTokenAddress: string
  isWrappedNativeToken: boolean
}

const allChains: IAllChains[] = [
  // {
  //   chainId: AVALANCHE_MAINNET,
  //   snowChainId: 0,
  //   name: 'Avalanche Mainnet',
  //   symbol: 'AVAX',
  //   icon: 'https://via.placeholder.com/20/FF0000/FFFFFF',
  //   chainTokenAddress: '0x',
  //   cChainTokenAddress: '0x',
  //   isWrappedNativeToken: true,
  // },
  // {
  //   chainId: AVALANCHE_TESTNET,
  //   snowChainId: 0,
  //   name: 'Avalanche Fujinet',
  //   symbol: 'AVAX',
  //   icon: 'https://via.placeholder.com/20/FF0000/FFFFFF',
  //   chainTokenAddress: '0x',
  //   cChainTokenAddress: '0x',
  //   isWrappedNativeToken: true,
  // },
  {
    chainId: RAINBOW_KITTIES,
    snowChainId: 0,
    name: 'Rainbow Kitties',
    symbol: 'KITTIES',
    icon: 'https://via.placeholder.com/20/FF0000/FFFFFF',
    chainTokenAddress: '0x',
    cChainTokenAddress: '0x',
    isWrappedNativeToken: true,
  },
]

export { allChains }
