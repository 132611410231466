import React from 'react'
import { AlertColor } from '@mui/material/Alert'

interface SnackbarProps {
  open: boolean
  severity: AlertColor
  message: string
}

const SnackbarContext = React.createContext({
  snackbarProps: {
    open: false,
    severity: 'success' as AlertColor,
    message: '',
  },
  setSnackbarProps: (snackBarProps: SnackbarProps) => {},
})

const SnackbarContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const setSnackbarProps = (snackbarProps: SnackbarProps) => {
    setState({ ...state, snackbarProps })
  }

  const initState = {
    snackbarProps: {
      open: false,
      severity: 'success' as AlertColor,
      message: '',
    },
    setSnackbarProps,
  }

  const [state, setState] = React.useState<{
    snackbarProps: SnackbarProps
    setSnackbarProps: any
  }>(initState)

  return (
    <SnackbarContext.Provider value={state}>
      {children}
    </SnackbarContext.Provider>
  )
}

const useSnackbar = () => {
  const context = React.useContext(SnackbarContext)
  if (context === undefined)
    throw new Error('useSnackbar must be used within SnackbarProvider')
  return context
}

export { SnackbarContextProvider, useSnackbar }
