import Link from '@mui/material/Link'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavigationItem = ({
  to,
  text,
  disabled,
  color,
}: {
  to: string
  text: string
  disabled?: boolean
  color?: string
}) => {
  const navigate = useNavigate()
  // Ensure that the link is tab accessible
  return (
    <Link
      disabled={disabled}
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        margin: '0 2rem',
        fontSize: '1rem',
      }}
      onClick={() => navigate(to)}
      underline={disabled ? 'none' : 'hover'}
      component={'button'}
      color={color || ((theme) => theme.palette.common.white)}
    >
      {text}
    </Link>
  )
}

export { NavigationItem }
