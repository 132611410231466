import { BigNumber, ethers, Signer } from 'ethers'
import { REGISTRAR_CONTRACT } from '../constants'
import { RegistrarAbi } from '../abis'
import { Registration } from '../models/Registration'
import { parseEther } from 'ethers/lib/utils'
import { CompletedRegistration } from '../models/CompletedRegistration'

const registrarContract = new ethers.Contract(
  REGISTRAR_CONTRACT,
  JSON.stringify(RegistrarAbi)
)

class Registrar {
  public registrarContract: ethers.Contract
  public signer: Signer
  public account: string
  public connectedSigner: ethers.Contract

  constructor(signer: Signer, account: string) {
    this.registrarContract = registrarContract
    this.signer = signer
    this.account = account
    this.connectedSigner = this.registrarContract.connect(this.signer)
  }

  public async createSubnet(
    name: string,
    symbol: string,
    totalSupply: string,
    chainId: string,
    enableBridge: boolean,
    bridgePercent: number
  ) {
    try {
      const { registrationFee, monthlyFee } = await this.getCurrentFees()
      const totalFee = BigNumber.from(registrationFee).add(
        BigNumber.from(monthlyFee)
      )
      return (
        await this.registrarContract
          .connect(this.signer)
          .registerNewSubnet(
            name,
            symbol,
            totalSupply,
            chainId,
            enableBridge,
            bridgePercent,
            {
              value: totalFee,
            }
          )
      ).wait()
    } catch (err: any) {
      throw err
    }
  }

  public async getCurrentFees() {
    const [registrationFee, monthlyFee] = await Promise.all([
      this.connectedSigner.registrationFee(),
      this.connectedSigner.monthlyFee(),
    ])
    return {
      registrationFee: BigNumber.from(registrationFee).toString(),
      monthlyFee: BigNumber.from(monthlyFee).toString(),
    }
  }

  public async getMinimumExtensionFee(): Promise<BigNumber> {
    return this.connectedSigner.minimumExtensionFee()
  }

  private async getNumRegistrationsForUser() {
    return BigNumber.from(
      await this.registrarContract
        .connect(this.signer)
        .getNumRegistrationsForUser(this.account)
    ).toNumber()
  }

  public async extendSubnet(id: string, value: string) {
    try {
      return (
        await this.connectedSigner.extendSubnet(id, {
          value: parseEther(value),
        })
      ).wait()
    } catch (err) {
      throw err
    }
  }

  public async getAllRegistrationForUser() {
    const nums = await this.getNumRegistrationsForUser()
    let registrations = await Promise.all(
      new Array(nums).fill(undefined).map((el, i) => {
        return this.registrarContract
          .connect(this.signer)
          .getRegistrationForUserAtIndex(this.account, i)
      })
    )
    registrations = await Promise.all(
      registrations.map(async (registration, i) => {
        const newRegistration = new Registration(registration)
        if (newRegistration.status === 1) {
          // TODO: If completed, we should call the complete to get rpc endpoint.
          const completed =
            await this.connectedSigner.getCompletedRegistrationForUserAtIndex(
              this.account,
              i
            )
          newRegistration.completed = new CompletedRegistration(completed)
        }
        return newRegistration
      })
    )
    return registrations
  }

  public async withdraw(amount: number) {
    try {
      return (await this.connectedSigner.withdraw(amount)).wait()
    } catch (err) {
      throw err
    }
  }

  public async getSecondsForValue(amount: string) {
    const parsed = parseEther(amount)
    try {
      return (
        BigNumber.from(
          await this.connectedSigner.secondsForValue(parsed)
        ).toNumber() * 1000
      )
    } catch (err) {
      throw err
    }
  }

  public async listenPending() {}
}

export { registrarContract, Registrar }
