import React from 'react'
import { formatEther, parseEther } from '@ethersproject/units'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useWeb3React } from '@web3-react/core'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { AVALANCHE_MAINNET, CHAIN_LABELS } from '../constants'
import { allChains } from '../mocks/allChains'
import { Navigation } from '../components/Navigation'
import { PageContainer } from '../components/PageContainer'
import { Container } from '../components/Container'
import { FormContainer } from '../components/FormContainer'
import { Title } from '../components/Title'

interface State {
  fromChainId: number
  toChainId?: number
  balance?: string
  tokenName?: string
  valueToSend?: string
  isBothChainSelected?: boolean
  tabIndex: number
}

const Bridge = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { chainId, account, library } = useWeb3React()
  const allAvailableChains = allChains.filter((c) => c.chainId !== chainId)
  const [values, setValues] = React.useState<State>({
    tabIndex: 0,
  } as any)

  React.useEffect((): any => {
    if (!!account && !!library && !!chainId) {
      let stale = false

      library
        .getBalance(account)
        .then((balance: number) => {
          if (!stale) {
            setValues((v) => ({
              ...v,
              fromChainId: chainId!,
              balance: formatEther(balance),
              tokenName: allChains.find(
                (el) => el.chainId === values.fromChainId
              )?.symbol,
            }))
          }
        })
        .catch(() => {
          if (!stale) {
            setValues((v) => ({
              ...v,
              fromChainId: chainId!,
              balance: '0',
              tokenName: allChains.find(
                (el) => el.chainId === values.fromChainId
              )?.symbol,
            }))
          }
        })

      return () => {
        stale = true
      }
    }
  }, [values.fromChainId, values.toChainId, account, library, chainId])

  return (
    <Navigation>
      <PageContainer>
        <Container>
          <Title
            extraMargin
            title="Bridge Transfer"
            subtitle="This bridge currently allows you to transfer from Fuji or Mainnet to your own subnet."
          />
          <Container>
            <Tabs
              sx={{ mb: '1rem' }}
              variant="fullWidth"
              value={values.tabIndex}
              onChange={(e) =>
                setValues({
                  ...values,
                  tabIndex: values.tabIndex === 0 ? 1 : 0,
                })
              }
              centered
            >
              <Tab label="Transfer" />
              <Tab label="Redeem (Soon)" disabled />
            </Tabs>
            {values.tabIndex === 0 ? (
              <>
                <Container>
                  <Title
                    title="Bridge Transfer"
                    subtitle="From Chain is defaulted to MetaMask current network. Currently, support Rainbow Kitties, Fuji, and Avalanche Mainnet."
                  />
                  <FormContainer>
                    <FormControl
                      sx={{ flexBasis: '200%', mr: 2, alignSelf: 'center' }}
                    >
                      <InputLabel id="fromChainId">From Chain</InputLabel>
                      <Select
                        labelId="fromChainId"
                        id="fromChainId"
                        name="fromChainId"
                        value={values?.fromChainId || ''}
                        label="From Chain"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            fromChainId: Number(e.target.value),
                          })
                        }}
                      >
                        <MenuItem value={chainId || AVALANCHE_MAINNET}>
                          <img
                            loading="lazy"
                            style={{
                              marginRight: '1rem',
                              verticalAlign: 'sub',
                            }}
                            width="20"
                            src="https://via.placeholder.com/20/FF0000/FFFFFF"
                            alt={CHAIN_LABELS[chainId || AVALANCHE_MAINNET]}
                          />
                          {CHAIN_LABELS[chainId || AVALANCHE_MAINNET]}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {matches ? (
                      <ArrowForwardIcon sx={{ margin: '1rem 0' }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ margin: '0 auto' }} />
                    )}
                    <FormControl
                      sx={{ flexBasis: '200%', mr: 2, alignSelf: 'center' }}
                    >
                      <InputLabel id="toChainId">To Chain</InputLabel>
                      <Select
                        name="toChainId"
                        labelId="toChainId"
                        id="toChainId"
                        value={values?.toChainId || ''}
                        label="To Chain"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            toChainId: Number(e.target.value),
                          })
                        }}
                      >
                        {allAvailableChains.map((c) => {
                          return (
                            <MenuItem value={c.chainId} key={c.chainId}>
                              <img
                                loading="lazy"
                                style={{
                                  marginRight: '1rem',
                                  verticalAlign: 'sub',
                                }}
                                width="20"
                                src="https://via.placeholder.com/20/FF0000/FFFFFF"
                                alt={CHAIN_LABELS[c.chainId]}
                              />
                              {CHAIN_LABELS[c.chainId]}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </FormContainer>
                </Container>
                <Container>
                  <Title
                    title="Token Balance"
                    subtitle="This section displays your current token balance"
                  />
                  <FormContainer>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="tokenName">Token Name</InputLabel>
                      <OutlinedInput
                        label="Token Name"
                        id="tokenName"
                        disabled
                        value={values.tokenName || ''}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="tokenBalance">Balance</InputLabel>
                      <OutlinedInput
                        label="Balance"
                        id="tokenBalance"
                        disabled
                        value={values.balance || 0.0}
                      />
                    </FormControl>
                  </FormContainer>
                </Container>
                <Container>
                  <Title
                    title="Amount Transfer"
                    subtitle="Enter the amount to be sent to the destination chain."
                  />
                  <FormContainer>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="amountToSend">Amount to Send</InputLabel>
                      <OutlinedInput
                        label="Amount to Send"
                        id="amountToSend"
                        value={values.valueToSend || 0}
                        onChange={(e) => {
                          if (e.target.value.match(/[\d.]/)) {
                            setValues({
                              ...values,
                              valueToSend: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setValues({
                                  ...values,
                                  valueToSend: formatEther(
                                    parseEther(values.balance!)
                                  ),
                                })
                              }}
                              edge="end"
                            >
                              <Typography
                                color={(theme) => theme.palette.primary.main}
                              >
                                Max
                              </Typography>
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </FormContainer>
                </Container>
                <FormContainer>
                  <FormControl sx={{ width: '100%' }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                      variant="contained"
                    >
                      Transfer
                    </Button>
                  </FormControl>
                </FormContainer>
              </>
            ) : (
              <Typography>Under construction</Typography>
            )}
          </Container>
        </Container>
      </PageContainer>
    </Navigation>
  )
}

export default Bridge
