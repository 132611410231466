import { useState, useEffect } from 'react'
import { Block } from '@ethersproject/abstract-provider'
import { JsonRpcProvider } from '@ethersproject/providers'
import { Transaction } from 'ethers/lib/ethers'
import { BigNumber } from '@ethersproject/bignumber'

export interface TransactionWithBlockNumber extends Transaction {
  blockNumber: BigNumber
  timestamp: number
}
export const useLatestTransactions = (
  provider?: JsonRpcProvider,
  latestBlocks?: Block[]
) => {
  const [latestTransactions, setLatestTransactions] =
    useState<TransactionWithBlockNumber[]>()

  useEffect(() => {
    if (!provider || !latestBlocks) {
      return
    }

    const readLatestTransactions = async () => {
      const transactions = latestBlocks.reduce((prev, curr) => {
        prev = [...prev, ...curr.transactions]
        return prev
      }, [] as string[])

      let latestFiveTransactions: TransactionWithBlockNumber[] =
        await Promise.all(
          (transactions || [])
            .slice(0, 5)
            .map((t) => provider.send('eth_getTransactionByHash', [t]))
        )

      latestFiveTransactions = latestFiveTransactions.map((transaction) => {
        const block = latestBlocks.find(
          (b) => b.number === BigNumber.from(transaction.blockNumber).toNumber()
        ) as Block
        return {
          ...transaction,
          timestamp: block.timestamp,
        }
      })

      setLatestTransactions(latestFiveTransactions)
      // const blockNum = await provider.getBlockNumber()
      // let lastBlocks = []
      // if (blockNum < 5) {
      //   lastBlocks = await Promise.all(
      //     new Array(blockNum)
      //       .fill(undefined)
      //       .map((el: any, i: number) =>
      //         provider.send('eth_getBlockByNumber', [
      //           `0x${(blockNum - i).toString(16)}`,
      //           false,
      //         ])
      //       )
      //   )
      // } else {
      //   lastBlocks = await Promise.all(
      //     new Array(5)
      //       .fill(undefined)
      //       .map((el: any, i: number) =>
      //         provider.send('eth_getBlockByNumber', [
      //           `0x${(blockNum - i).toString(16)}`,
      //           false,
      //         ])
      //       )
      //   )
      // }
      // const lastFiveBlocks = lastBlocks.map((b: any) => {
      //   return provider.formatter.block(b)
      // })
      // setLatestTransactions(lastFiveBlocks)
    }

    // provider.on('block', readLatestBlock)
    // readLatestBlock()
    //
    // return () => {
    //   provider.off('block', readLatestBlock)
    // }
    readLatestTransactions()
  }, [provider, latestBlocks])

  return latestTransactions
}
