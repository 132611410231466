import { NavLink, useParams } from 'react-router-dom'
import { blockTxsURL, blockURL } from '../url'
import { commify } from '@ethersproject/units'
import Timestamp from './Timestamp'
import React from 'react'
import { Block } from '@ethersproject/abstract-provider'

type ILatestBlock = {
  latestBlock: Block
}

const LatestBlock: React.FC<ILatestBlock> = ({ latestBlock }) => {
  const { symbol } = useParams()
  return (
    <>
      <div className="flex w-full justify-center items-center">
        <NavLink
          className="flex flex-col space-y-1 text-sm text-gray-500 hover:text-link-blue grow w-full"
          to={blockURL(latestBlock.number, symbol!)}
        >
          <div className="text-blue-400 hover:text-link-blue-hover">
            {commify(latestBlock.number)}
          </div>
          <Timestamp value={latestBlock.timestamp} showString={false} />
        </NavLink>
        <div className="flex flex-col grow w-full">
          <NavLink
            className="flex flex-col space-y-1 text-sm text-gray-500 hover:text-link-blue grow w-full"
            to={blockURL(latestBlock.number, symbol!)}
          >
            <div>
              <span className="text-black">Hash: </span>
              <span className="text-blue-400">
                {latestBlock.hash.substring(0, 12)}...
              </span>
            </div>
          </NavLink>
          <NavLink
            className="flex flex-col space-y-1 text-sm text-gray-500 hover:text-link-blue grow w-full"
            to={blockTxsURL(latestBlock.number, symbol!)}
          >
            <span className="text-black">
              Txs:{' '}
              <span className="text-blue-400 hover:text-link-blue-hover">
                {commify(latestBlock.transactions.length)} txns
              </span>
            </span>
          </NavLink>
        </div>
      </div>
      <hr className="my-2" />
    </>
  )
}

export { LatestBlock }
