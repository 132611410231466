import React from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Button from '@mui/material/Button'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'

function getErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}

const DisconnectButton = () => {
  const { deactivate, active, error } = useWeb3React()
  return (
    <div>
      {(active || error) && (
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deactivate()
          }}
        >
          Deactivate
        </Button>
      )}

      {!!error && (
        <h4 style={{ marginTop: '1rem', marginBottom: '0' }}>
          {getErrorMessage(error)}
        </h4>
      )}
    </div>
  )
}

export { DisconnectButton }
