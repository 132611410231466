import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import {
  AVALANCHE_MAINNET,
  AVALANCHE_TESTNET,
  ETHEREUM_MAINNET,
  RAINBOW_KITTIES,
  SUPPORTED_CHAIN,
} from '../constants'

export const RPC_URLS: { [chainId: number]: string } = SUPPORTED_CHAIN

export const injected = new InjectedConnector({
  supportedChainIds: [
    AVALANCHE_MAINNET,
    AVALANCHE_TESTNET,
    RAINBOW_KITTIES,
    ETHEREUM_MAINNET,
  ],
})

export const network = new NetworkConnector({
  urls: {
    [RAINBOW_KITTIES]: RPC_URLS[RAINBOW_KITTIES],
    [AVALANCHE_TESTNET]: RPC_URLS[AVALANCHE_TESTNET],
    [AVALANCHE_MAINNET]: RPC_URLS[AVALANCHE_MAINNET],
  },
  defaultChainId: AVALANCHE_TESTNET,
})
