import React from 'react'
import { useWeb3React } from '@web3-react/core'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import Badge from '@mui/material/Badge'

import { MetamaskConnect } from './MetamaskConnect'
import { DisconnectButton } from './DisconnectButton'
import { FlexRow } from './FlexRow'
import { NavigationItem } from './NavigationItem'
import { useSnackbar } from '../contexts/SnackbarProvider'
import { CustomizedSnackbars } from './CustomizedSnackbars'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
const Header = () => {
  const navigate = useNavigate()
  const { snackbarProps, setSnackbarProps } = useSnackbar()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarProps({
      ...snackbarProps,
      open: false,
    })
  }
  const { active, error } = useWeb3React()
  return (
    <>
      <CustomizedSnackbars
        severity={snackbarProps.severity}
        open={snackbarProps.open}
        message={`${snackbarProps.message}`}
        handleClose={handleClose}
      />
      <Box
        sx={{
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            width: '1440px',
            margin: '0 auto',
            height: '4rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 2rem',
          }}
        >
          <FlexRow>
            <Box
              sx={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
              <AcUnitIcon color={'primary'} sx={{ mr: 1, fontSize: '2rem' }} />
              <Typography
                fontSize="1.5rem"
                color={'white'}
                sx={{ mr: '2rem', fontWeight: 'bold' }}
              >
                SnowGenesis
              </Typography>
            </Box>
            <>
              {matches && (
                <>
                  <NavigationItem to={'/subnets'} text="Subnets" />
                  <Badge badgeContent={'Soon'} color="primary">
                    <NavigationItem
                      to={'/bridge'}
                      text="Bridge"
                      disabled
                      color={'gray'}
                    />
                  </Badge>
                  <Badge badgeContent={'Soon'} color="primary">
                    <NavigationItem
                      to={'/explorer'}
                      text="Explorer"
                      disabled
                      color={'gray'}
                    />
                  </Badge>
                </>
              )}
            </>
          </FlexRow>
          {matches && (
            <FlexRow>
              <>{active && <NavigationItem to={'/profile'} text="Profile" />}</>
              {!active ? <MetamaskConnect /> : <DisconnectButton />}
              <Box sx={{ marginLeft: 2 }}>
                {active ? '🟢' : error ? '🔴' : '🟠'}
              </Box>
            </FlexRow>
          )}
        </Box>
      </Box>
    </>
  )
}

export { Header }
