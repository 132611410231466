import React from 'react'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { Header } from './Header'
import blockchain from '../images/blockchain-background.jpeg'
import { FlexRow } from './FlexRow'

const Navigation = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[]
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        overflowX: 'hidden',
        minHeight: '100vh',
      }}
    >
      {/* Background Image for Snownets */}
      <Box
        sx={{
          position: 'fixed',
          backgroundImage: `url(${blockchain})`,
          height: '100%',
          width: '100%',
          zIndex: -1,
          backgroundPosition: 'left',
          backgroundSize: 'cover',
        }}
      />
      <Header />

      <Box
        sx={{
          gridTemplateColumns: 'fit-content',
          maxWidth: matches ? '50rem' : '90%',
          margin: matches ? 'auto' : '0 auto',
          minHeight: '100vh',
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          background: '#f0f0f0',
        }}
      >
        <Box
          sx={{
            maxWidth: '1440px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 auto',
            padding: matches ? 1 : '2rem',
            flexDirection: matches ? 'row' : 'column',
          }}
        >
          <FlexRow>
            <AcUnitIcon color={'primary'} sx={{ mr: 1, fontSize: '2rem' }} />
            <Typography
              fontSize="1.5rem"
              color={'#4379FF'}
              sx={{ mr: '2rem', fontWeight: 'bold' }}
            >
              SnowGenesis
            </Typography>
          </FlexRow>
          <FlexRow>
            <Link href="mailto:hello@snowgenesis.com" target="_blank">
              hello@snowgenesis.com
            </Link>
          </FlexRow>
        </Box>
      </Box>
    </Box>
  )
}

export { Navigation }
