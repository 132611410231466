import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useMediaQuery, useTheme } from '@mui/material'

const Title = ({
  title,
  subtitle,
  extraMargin,
}: {
  title: string
  subtitle?: string
  extraMargin?: boolean
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        mt: matches ? '0.25rem' : '1rem',
        mb: extraMargin ? '2rem' : 'initial',
        pl: '1rem',
      }}
    >
      <Typography
        component="h2"
        variant="h5"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography component="h3" variant="subtitle2" mb="1rem">
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

export { Title }
