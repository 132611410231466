import React from 'react'
import Box from '@mui/material/Box'

const FlexRow = ({
  children,
  justifyContent = 'center',
}: {
  children: JSX.Element | JSX.Element[] | boolean
  justifyContent?: string
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent,
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  )
}

export { FlexRow }
