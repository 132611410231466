import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Subnets from './pages/Subnets'
import Bridge from './pages/Bridge'
import Profile from './pages/Profile'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { injected, network } from './connectors'
import { useEagerConnect } from './hooks/index'
import Home from './pages/Home'
import Main from './Main'
import ScannerHome from './Home'

const Block = React.lazy(
  () => import(/* webpackChunkName: "block", webpackPrefetch: true */ './Block')
)
const BlockTransactions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "blocktxs", webpackPrefetch: true */ './BlockTransactions'
    )
)
const Address = React.lazy(
  () =>
    import(/* webpackChunkName: "address", webpackPrefetch: true */ './Address')
)
const Transaction = React.lazy(
  () =>
    import(/* webpackChunkName: "tx", webpackPrefetch: true */ './Transaction')
)
const ListBlocks = React.lazy(
  () =>
    import(
      /* webpackChunkName: "blocks", webpackPrefetch: true */ './ListBlocks'
    )
)
const London = React.lazy(
  () =>
    import(
      /* webpackChunkName: "london", webpackPrefetch: true */ './special/london/London'
    )
)
const PageNotFound = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notfound", webpackPrefetch: true */ './PageNotFound'
    )
)

export enum ConnectorNames {
  Injected = 'Injected',
  Network = 'Network',
}

export const connectorsByName: {
  [connectorName in ConnectorNames]: AbstractConnector
} = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Network]: network,
}

function App() {
  useEagerConnect()
  return (
    <div className="h-screen flex flex-col">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="subnets" element={<Subnets />} />
          <Route path="bridge" element={<Bridge />} />
          <Route path="profile" element={<Profile />} />
          <Route path="*" element={<Main />}>
            <Route path="explorer/:symbol" element={<ScannerHome />} />
            <Route path="explorer/:symbol/blocks" element={<ListBlocks />} />
            <Route
              path="explorer/:symbol/block/:blockNumberOrHash"
              element={<Block />}
            />
            <Route
              path="explorer/:symbol/special/london"
              element={<London />}
            />
            <Route
              path="explorer/:symbol/block/:blockNumber/txs"
              element={<BlockTransactions />}
            />
            <Route
              path="explorer/:symbol/tx/:txhash/*"
              element={<Transaction />}
            />
            <Route
              path="explorer/:symbol/address/:addressOrName/*"
              element={<Address />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
