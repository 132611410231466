import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const AlertWarning = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const text = matches
    ? 'Please connect to your Metamask wallet.'
    : 'Mobile view is not supported yet. Please use a desktop.'
  return (
    <Box
      component={'h2'}
      sx={{
        marginY: '2rem',
        margin: '2rem auto',
      }}
    >
      <Alert severity="warning">{text}</Alert>
    </Box>
  )
}

export { AlertWarning }
