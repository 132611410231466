import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Registrar } from '../apis/registrarContract'
import { COMPLETED_TOPIC } from '../constants'

export function useListener() {
  const { active, library, account } = useWeb3React()
  const [refreshed, setRefreshed] = useState(false)

  useEffect(() => {
    if (!!account && !!active && !!library) {
      const addListener = async () => {
        setRefreshed(true)
      }

      const re = new Registrar(library.getSigner(), account)
      re.connectedSigner.on({ topics: [COMPLETED_TOPIC] }, addListener)

      return () => {
        re.connectedSigner.off({ topics: [COMPLETED_TOPIC] }, addListener)
      }
    }
    return
  }, [active, account, library])

  return { refreshed }
}
