import { NavLink, useParams } from 'react-router-dom'
import { addressURL, transactionURL } from '../url'
import Timestamp from './Timestamp'
import React from 'react'
import { TransactionWithBlockNumber } from '../useLatestTransactions'

type ILatestTransactions = {
  latestTransaction: TransactionWithBlockNumber
}

const LatestTransaction: React.FC<ILatestTransactions> = ({
  latestTransaction,
}) => {
  const { symbol } = useParams()
  return (
    <>
      <div className="flex w-full justify-center items-center">
        <NavLink
          className="flex flex-col space-y-1 text-sm text-gray-500 hover:text-link-blue grow w-full"
          to={transactionURL(latestTransaction.hash || '', symbol!)}
        >
          <div className="text-blue-400 hover:text-link-blue-hover">
            {latestTransaction.hash?.substring(0, 12)}...
          </div>
          <Timestamp value={latestTransaction.timestamp} showString={false} />
        </NavLink>
        <div className="flex flex-col grow w-full">
          <NavLink
            className="flex flex-col space-y-1 text-sm text-gray-500 hover:text-link-blue grow w-full"
            to={addressURL(latestTransaction.from || '', symbol!)}
          >
            <div>
              <span className="text-black">From </span>
              <span className="text-blue-400 hover:text-link-blue-hover">
                {latestTransaction.from?.substring(0, 12)}...
              </span>
            </div>
          </NavLink>
          <NavLink
            className="flex flex-col space-y-1 text-sm text-gray-500 grow w-full"
            to={
              latestTransaction.to
                ? addressURL(latestTransaction.to || '', symbol!)
                : ''
            }
          >
            <div>
              <span className="text-black">To </span>
              <span
                className={`${
                  latestTransaction.to &&
                  'text-blue-400 hover:text-link-blue-hover'
                }`}
              >
                {latestTransaction.to
                  ? `${latestTransaction.to.substring(0, 12)}...`
                  : 'Contract Creation'}
              </span>
            </div>
          </NavLink>
        </div>
      </div>
      <hr className="my-2" />
    </>
  )
}

export { LatestTransaction }
