import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { ConnectionStatus } from './types'
import { SnowGenesisScanConfig } from './useConfig'
import { useParams } from 'react-router-dom'

type ConnectionErrorPanelProps = {
  connStatus: ConnectionStatus
  config?: SnowGenesisScanConfig
}

const ConnectionErrorPanel: React.FC<ConnectionErrorPanelProps> = ({
  connStatus,
  config,
}) => {
  const { symbol } = useParams()
  return (
    <div className="h-screen flex flex-col bg-gray-300 font-sans">
      <div className="m-auto h-60 text-gray-700 text-lg min-w-lg max-w-lg">
        <Step type="wait" msg="Trying to connect to EVM node..." />
        <div className="flex space-x-2">
          <span className="ml-7 text-base">{config?.evmURL}</span>
        </div>
        {connStatus === ConnectionStatus.NOT_REGISTERED && (
          <Step
            type="error"
            msg={`The symbol: ${symbol} has not been registered.`}
          >
            <p>
              If you'd like to register a subnet / blockchain, visit{' '}
              <a style={{ color: 'blue' }} href="/subnets">
                Subnets page
              </a>
            </p>
          </Step>
        )}
        {connStatus === ConnectionStatus.NOT_COMPLETED && (
          <Step
            type="error"
            msg={`The symbol: ${symbol} has not been completed`}
          >
            <p>
              Please wait for the rpc to be propogated before accessing this
              rpc.
            </p>
          </Step>
        )}
        {connStatus === ConnectionStatus.NOT_ETH_NODE && (
          <Step type="error" msg="It does not seem to be an ETH node">
            <p>Make sure your browser can access the URL above.</p>
          </Step>
        )}
        {connStatus === ConnectionStatus.NOT_ERIGON && (
          <>
            <Step type="ok" msg="It is an ETH node" />
            <Step type="error" msg="It does not seem to be an EVM node">
              Make sure you rpcdaemon with SnowGenesisScan patches is up and
              running and the <strong>erigon_</strong> namespace is enabled
              according to the{' '}
              <a
                href="https://github.com/wmitsuda/snowgenesisscan#install-snowgenesisscan-patches-on-top-of-EVM"
                target="_blank"
                rel="noreferrer noopener"
                className="font-bold text-blue-800 hover:underline"
              >
                instructions
              </a>
              .
            </Step>
          </>
        )}
        {connStatus === ConnectionStatus.NOT_OTTERSCAN_PATCHED && (
          <>
            <Step type="ok" msg="It is an EVM node" />
            <Step
              type="error"
              msg="It does not seem to contain up-to-date SnowGenesisScan patches"
            >
              Make sure you compiled rpcdaemon with compatible SnowGenesisScan
              patches and enabled <strong>ots_</strong> namespace according to
              the{' '}
              <a
                href="https://github.com/wmitsuda/snowgenesisscan#install-snowgenesisscan-patches-on-top-of-EVM"
                target="_blank"
                rel="noreferrer noopener"
                className="font-bold text-blue-800 hover:underline"
              >
                instructions
              </a>
              .
            </Step>
          </>
        )}
      </div>
    </div>
  )
}

type StepProps = {
  type: 'wait' | 'ok' | 'error'
  msg: string
}

const Step: React.FC<StepProps> = React.memo(({ type, msg, children }) => (
  <>
    <div className="flex space-x-2">
      {type === 'wait' && (
        <span className="text-gray-600">
          <FontAwesomeIcon icon={faClock} size="1x" />
        </span>
      )}
      {type === 'ok' && (
        <span className="text-green-600">
          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
        </span>
      )}
      {type === 'error' && (
        <span className="text-red-600">
          <FontAwesomeIcon icon={faTimesCircle} size="1x" />
        </span>
      )}
      <span>{msg}</span>
    </div>
    {children && <div className="ml-7 mt-4 text-sm">{children}</div>}
  </>
))

export default React.memo(ConnectionErrorPanel)
