import React from 'react'
import { useWeb3React } from '@web3-react/core'

import Box from '@mui/material/Box'

import { AlertWarning } from './AlertWarning'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import { AVALANCHE_TESTNET, CHAIN_LABELS } from '../constants'

const PageContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | number | boolean
}) => {
  const { active, chainId } = useWeb3React()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <>
      {!active && <AlertWarning />}
      {active && chainId !== AVALANCHE_TESTNET && (
        <Box sx={{ mt: '4rem', display: 'flex', justifyContent: 'center' }}>
          <Button
            variant={'contained'}
            onClick={async () => {
              try {
                await window.ethereum?.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: `0x${AVALANCHE_TESTNET.toString(16)}` }],
                })
              } catch (switchError: any) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                  try {
                    await window.ethereum?.request({
                      method: 'wallet_addEthereumChain',
                      params: [
                        {
                          chainId: `0x${AVALANCHE_TESTNET.toString(16)}`,
                          chainName: CHAIN_LABELS[AVALANCHE_TESTNET],
                          rpcUrls: [
                            'https://api.avax-test.network/ext/bc/C/rpc',
                          ],
                          nativeCurrency: {
                            name: 'AVAX',
                            symbol: 'AVAX',
                            decimals: 18,
                          },
                          blockExplorerUrls: ['https://testnet.snowtrace.io/'],
                        },
                      ],
                    })
                  } catch (addError) {
                    // handle "add" error
                  }
                }
              }
            }}
          >
            Switch To Fuji
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: active && matches ? '4rem 0' : '2rem 0 4rem',
        }}
        component="form"
      >
        {children}
      </Box>
    </>
  )
}

export { PageContainer }
