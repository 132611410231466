import { useEffect, useState } from 'react'
import {
  JsonRpcProvider,
  JsonRpcBatchProvider,
  WebSocketProvider,
} from '@ethersproject/providers'
import { ConnectionStatus } from './types'
import { SnowGenesisScanConfig } from './useConfig'
// import { MIN_API_LEVEL } from "./params";

export const DEFAULT_ERIGON_URL = 'https://eu-2.saferpad.com/ava-test/'

export const useProvider = (
  config: SnowGenesisScanConfig = {}
): [ConnectionStatus, JsonRpcProvider | undefined] => {
  const [connStatus, setConnStatus] = useState<ConnectionStatus>(
    ConnectionStatus.CONNECTING
  )

  if (config.evmURL !== undefined) {
    if (config.evmURL === '') {
      console.info(`Using default EVM URL: ${DEFAULT_ERIGON_URL}`)
      config.evmURL = DEFAULT_ERIGON_URL
    } else {
      console.log(`Using configured EVM URL: ${config.evmURL}`)
    }
  }

  const [provider, setProvider] = useState<JsonRpcProvider | undefined>()
  useEffect(() => {
    if (!config.registration?.symbol) {
      setConnStatus(ConnectionStatus.NOT_REGISTERED)
      return
    } else if (
      !config.registration?.symbol &&
      config.registration?.status === 0
    ) {
      setConnStatus(ConnectionStatus.NOT_COMPLETED)
      return
    }

    if (config.evmURL === undefined) {
      setConnStatus(ConnectionStatus.NOT_ETH_NODE)
      setProvider(undefined)
      return
    }
    setConnStatus(ConnectionStatus.CONNECTING)

    const tryToConnect = async () => {
      let provider: JsonRpcProvider
      if (
        config.evmURL?.startsWith('ws://') ||
        config.evmURL?.startsWith('wss://')
      ) {
        provider = new WebSocketProvider(config.evmURL)
      } else {
        provider = new JsonRpcBatchProvider(config.evmURL)
      }

      // Check if it is at least a regular ETH node
      try {
        await provider.getBlockNumber()
        setConnStatus(ConnectionStatus.CONNECTED)
        setProvider(provider)
      } catch (err) {
        console.log(err)
        setConnStatus(ConnectionStatus.NOT_ETH_NODE)
        setProvider(undefined)
        return
      }

      // Check if it is an EVM node by probing a lightweight method
      // try {
      //   await provider.send("erigon_getHeaderByNumber", [blockNumber]);
      // } catch (err) {
      //   console.log(err);
      //   setConnStatus(ConnectionStatus.NOT_ERIGON);
      //   setProvider(undefined);
      //   return;
      // }

      // Check if it has SnowGenesisScan patches by probing a lightweight method
      // try {
      //   const level = await provider.send("ots_getApiLevel", []);
      //   if (level < MIN_API_LEVEL) {
      //     setConnStatus(ConnectionStatus.NOT_OTTERSCAN_PATCHED);
      //     setProvider(undefined);
      //   } else {
      //     setConnStatus(ConnectionStatus.CONNECTED);
      //     setProvider(provider);
      //   }
      // } catch (err) {
      //   console.log(err);
      //   setConnStatus(ConnectionStatus.NOT_OTTERSCAN_PATCHED);
      //   setProvider(undefined);
      // }
    }
    tryToConnect()
  }, [config.evmURL, config.registration?.status, config.registration?.symbol])

  return [connStatus, provider]
}
