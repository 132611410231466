import React, { useState, useContext } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBurn } from '@fortawesome/free-solid-svg-icons/faBurn'
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode'
import Logo from './Logo'
import { RuntimeContext } from './useRuntime'
import { useGenericSearch } from './search/search'
import { useLatestBlocks } from './useLatestBlocks'
import { LatestBlock } from './components/LatestBlock'
import { useLatestTransactions } from './useLatestTransactions'
import { LatestTransaction } from './components/LatestTransaction'

const CameraScanner = React.lazy(() => import('./search/CameraScanner'))

const Home: React.FC = () => {
  const { provider } = useContext(RuntimeContext)
  const [searchRef, handleChange, handleSubmit] = useGenericSearch()
  const latestBlocks = useLatestBlocks(provider)
  const latestTransactions = useLatestTransactions(provider, latestBlocks)
  const [isScanning, setScanning] = useState<boolean>(false)

  document.title = 'Home | SnowGenesisScan'

  const { symbol } = useParams()

  return (
    <>
      <div className="mx-auto flex flex-col flex-grow pb-5 w-4/5 md:w-4/5 lg:w-4/5 2xl:w-4/5">
        {isScanning && <CameraScanner turnOffScan={() => setScanning(false)} />}
        <div className="m-5 mb-10 flex items-end flex-grow max-h-64">
          <Logo />
        </div>
        <form
          className="flex flex-col"
          onSubmit={handleSubmit}
          autoComplete="off"
          spellCheck={false}
        >
          <div className="flex mb-10 m-auto w-2/3 justify-center">
            <input
              className="border-l border-t border-b rounded-l focus:outline-none px-2 py-1 w-full"
              type="text"
              size={50}
              placeholder={`Search by address / txn hash / block number${
                provider?.network.ensAddress ? ' / ENS name' : ''
              }`}
              onChange={handleChange}
              ref={searchRef}
              autoFocus
            />
            <button
              className="border rounded-r bg-skin-button-fill hover:bg-skin-button-hover-fill focus:outline-none px-2 py-1 text-base text-skin-button flex justify-center items-center"
              type="button"
              onClick={() => setScanning(true)}
              title="Scan an ETH address using your camera"
            >
              <FontAwesomeIcon icon={faQrcode} />
            </button>
          </div>
          <button
            className="mx-auto px-3 py-1 mb-10 rounded bg-skin-button-fill hover:bg-skin-button-hover-fill focus:outline-none"
            type="submit"
          >
            Search
          </button>
        </form>
        <div className="text-lg text-link-blue hover:text-link-blue-hover font-bold justify-center flex">
          {provider?.network.chainId !== 11155111 && (
            <NavLink to={`/explorer/${symbol}/special/london`}>
              <div className="flex space-x-2 items-baseline text-orange-500 hover:text-orange-700 hover:underline ">
                <span>
                  <FontAwesomeIcon icon={faBurn} />
                </span>
                <span>Check out the special dashboard for EIP-1559</span>
                <span>
                  <FontAwesomeIcon icon={faBurn} />
                </span>
              </div>
            </NavLink>
          )}
        </div>
        <div className="mx-auto h-auto w-full flex gap-2 flex-col lg:flex-row flex">
          <div className="flex-col text-left border p-4 my-4 rounded-md md:w-full">
            <div>
              <h4 className="font-bold">Latest Blocks</h4>
            </div>

            <div>
              <hr className="my-2" />
              {latestBlocks?.length ? (
                latestBlocks.map((latestBlock) => (
                  <LatestBlock
                    key={latestBlock.hash}
                    latestBlock={latestBlock}
                  />
                ))
              ) : (
                <span>This chain does not have blocks yet!</span>
              )}
            </div>
            <NavLink to={`/explorer/${symbol}/blocks`}>
              <div className="text-center mt-4 w-full">
                <button
                  className={`w-full rounded-md py-1 text-white ${
                    !latestBlocks?.length
                      ? 'disabled bg-gray-400 opacity-50'
                      : 'bg-blue-400'
                  }`}
                >
                  View all blocks
                </button>
              </div>
            </NavLink>
          </div>
          <div className="flex-col text-left border p-4 my-4 rounded-md md:w-full">
            <div>
              <h4 className="font-bold">Latest Transactions</h4>
            </div>

            <div>
              <hr className="my-2" />
              {latestTransactions?.length ? (
                latestTransactions.map((latestTransaction) => (
                  <LatestTransaction
                    key={latestTransaction.hash}
                    latestTransaction={latestTransaction}
                  />
                ))
              ) : (
                <span>This chain does not have transactions yet!</span>
              )}
            </div>

            <div className="text-center mt-4 w-full">
              <button className="w-full bg-gray-400 rounded-md py-1 text-white cursor-not-allowed	opacity-50">
                View all transactions (Soon)
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Home)
