import React, { useMemo } from 'react'
import { JsonRpcProvider } from '@ethersproject/providers'
import { SnowGenesisScanConfig, useConfig } from './useConfig'
import { useProvider } from './useProvider'
import { ConnectionStatus } from './types'

export type SnowGenesisScanRuntime = {
  config?: SnowGenesisScanConfig
  connStatus: ConnectionStatus
  provider?: JsonRpcProvider
}

export const useRuntime = (): SnowGenesisScanRuntime => {
  const [configOK, config] = useConfig()
  const [connStatus, provider] = useProvider(config)

  const runtime = useMemo(
    (): SnowGenesisScanRuntime => ({ config, connStatus, provider }),
    [config, connStatus, provider]
  )

  if (!configOK) {
    return { connStatus: ConnectionStatus.CONNECTING }
  }
  return runtime
}

export const RuntimeContext = React.createContext<SnowGenesisScanRuntime>(null!)
