import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const CustomizedSnackbars = ({
  open,
  message,
  handleClose,
  severity = 'success',
}: {
  open: boolean
  message: string
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void
  severity?: AlertColor
}) => {
  return (
    <Snackbar
      sx={{}}
      open={open}
      autoHideDuration={20000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export { CustomizedSnackbars }
