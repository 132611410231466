import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { AVALANCHE_TESTNET, REGISTRAR_CONTRACT } from './constants'
import { RPC_URLS } from './connectors'
import { RegistrarAbi } from './abis'
import { useParams } from 'react-router-dom'
import { Registration } from './models/Registration'
import { CompletedRegistration } from './models/CompletedRegistration'

export type SnowGenesisScanConfig = {
  evmURL?: string
  assetsURLPrefix?: string
  registration?: Registration
}

const provider = new ethers.providers.JsonRpcProvider(
  RPC_URLS[AVALANCHE_TESTNET]
)

export const useConfig = (): [boolean?, SnowGenesisScanConfig?] => {
  const { symbol } = useParams()
  const [configOK, setConfigOK] = useState<boolean>()
  const [config, setConfig] = useState<SnowGenesisScanConfig>()

  useEffect(() => {
    let newRegistration
    const readConfig = async () => {
      try {
        const [registration, completedRegistration] = await new ethers.Contract(
          REGISTRAR_CONTRACT,
          JSON.stringify(RegistrarAbi),
          provider
        ).getDataForSymbol(symbol)
        newRegistration = new Registration(registration)
        newRegistration.completed = new CompletedRegistration(
          completedRegistration
        )
        if (newRegistration.status !== 1) {
          setConfigOK(newRegistration.status !== 1)
          setConfig({
            registration: newRegistration,
          })
          return
        }
      } catch (err: any) {
        setConfig({
          evmURL: 'wss://eu-2.saferpad.com/ava-test/ws/',
          assetsURLPrefix: 'http://localhost:3001:',
          registration: {
            enableBridge: true,
            name: 'Rainbow Kitties',
            id: '4',
            totalSupply: '10000000000',
            goodUntil: new Date().getTime(),
            status: 1,
            symbol: 'RAINBOW',
            creator: '0x000',
            chainId: '',
            bridgePercent: 100,
            collapse: true,
          },
        })
        setConfigOK(true)
        return
      }

      if (newRegistration.status === 1) {
        console.info('Loaded app config')
        // console.info(_config)
        setConfig({
          evmURL: newRegistration.completed?.wsUrl,
          assetsURLPrefix: 'http://localhost:3001:',
          registration: newRegistration,
        })
        setConfigOK(newRegistration.status === 1)
      }
    }
    readConfig()
  }, [symbol])

  return [configOK, config]
}
