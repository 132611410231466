import React from 'react'
import Box from '@mui/material/Box'
import { useMediaQuery, useTheme } from '@mui/material'

const Container = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | any
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        position: 'relative',
        boxShadow: 3,
        mb: 2,
        background: '#f0f0f0',
        borderRadius: (theme) => theme.shape.borderRadius,
        padding: matches ? 4 : 2,
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

export { Container }
