import { useState, useEffect } from 'react'
import { Block } from '@ethersproject/abstract-provider'
import { JsonRpcProvider } from '@ethersproject/providers'

export const useLatestBlocks = (
  provider?: JsonRpcProvider,
  maxBlocks: number | string = 5
) => {
  const [latestBlock, setLatestBlocks] = useState<Block[]>()

  useEffect(() => {
    if (!provider) {
      return
    }

    const readLatestBlock = async () => {
      const blockNum = await provider.getBlockNumber()
      let lastBlocks = []
      if (blockNum < 5 || maxBlocks === 'latest') {
        lastBlocks = await Promise.all(
          new Array(blockNum)
            .fill(undefined)
            .map((el: any, i: number) =>
              provider.send('eth_getBlockByNumber', [
                `0x${(blockNum - i).toString(16)}`,
                false,
              ])
            )
        )
      } else {
        lastBlocks = await Promise.all(
          new Array(5)
            .fill(undefined)
            .map((el: any, i: number) =>
              provider.send('eth_getBlockByNumber', [
                `0x${(blockNum - i).toString(16)}`,
                false,
              ])
            )
        )
      }
      const lastFiveBlocks = lastBlocks.map((b: any) => {
        return provider.formatter.block(b)
      })
      setLatestBlocks(lastFiveBlocks)
    }

    provider.on('block', readLatestBlock)
    readLatestBlock()

    return () => {
      provider.off('block', readLatestBlock)
    }

    // const listener = async (blockNumber: number) => {
    //   const _raw = await provider.send("eth_getBlockByNumber", [
    //     blockNumber,
    //   ]);
    //   const _block = provider.formatter.block(_raw);
    //   setLatestBlock(_block);
    // };
    //
    // provider.on("block", listener);
    // return () => {
    //   provider.removeListener("block", listener);
    // };
  }, [provider, maxBlocks])

  return latestBlock
}
