function stripCommaAndPeriod(x: string) {
  return x.replace(/[.,]/g, '')
}

function numberWithCommas(x: string) {
  return stripCommaAndPeriod(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function breakByteCode(x: string) {
  const totalLength = x.length
  let start = 0
  let result = ''

  do {
    result += `${x.substr(start, 128)}\n`
    start += 128
  } while (start < totalLength)
  return result
}

export { stripCommaAndPeriod, numberWithCommas, breakByteCode }
