import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Spinner } from './Spinner'
import { ConnectorNames, connectorsByName } from '../App'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useInactiveListener } from '../hooks'
import { AVALANCHE_TESTNET, CHAIN_LABELS } from '../constants'

const MetamaskConnect = () => {
  const context = useWeb3React<Web3Provider>()
  const { connector, activate, error } = context
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState<any>()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!!activatingConnector)

  const currentConnector = connectorsByName[ConnectorNames.Injected]
  const activating = currentConnector === activatingConnector
  const connected = currentConnector === connector
  const disabled = !!activatingConnector || connected || !!error

  return (
    <Button
      variant="contained"
      disabled={disabled}
      key={ConnectorNames.Injected}
      onClick={() => {
        setActivatingConnector(currentConnector)
        activate(connectorsByName[ConnectorNames.Injected], async (error) => {
          try {
            await window.ethereum?.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `0x${AVALANCHE_TESTNET.toString(16)}` }],
            })
          } catch (switchError: any) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              try {
                await window.ethereum?.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: `0x${AVALANCHE_TESTNET.toString(16)}`,
                      chainName: CHAIN_LABELS[AVALANCHE_TESTNET],
                      rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
                      nativeCurrency: {
                        name: 'AVAX',
                        symbol: 'AVAX',
                        decimals: 18,
                      },
                      blockExplorerUrls: ['https://testnet.snowtrace.io/'],
                    },
                  ],
                })
              } catch (addError) {
                // handle "add" error
              }
            }
          }
          if (error) {
            setActivatingConnector(undefined)
          }
        })
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: activating ? '0 1rem' : '0',
        }}
      >
        {activating && (
          <Spinner
            color={'blue'}
            style={{ height: '15%', marginLeft: '-1rem' }}
          />
        )}
      </Box>
      Connect to Metamask
    </Button>
  )
}

export { MetamaskConnect }
