import { BigNumber } from 'ethers'

interface ICompletedRegistration {
  blockchainId: string
  bridgeAddressCChain: string
  bridgeAddressSubnet: string
  id: BigNumber | string
  rpcUrl: string
  subnetId: string
  tokenAddressCChain: string
  vmId: string
  wrappedTokenAddressSubnet: string
}

class CompletedRegistration implements ICompletedRegistration {
  public blockchainId: string
  public bridgeAddressCChain: string
  public bridgeAddressSubnet: string
  public id: BigNumber | string
  public rpcUrl: string
  public wsUrl: string
  public subnetId: string
  public tokenAddressCChain: string
  public vmId: string
  public wrappedTokenAddressSubnet: string

  constructor(completedRegistration: ICompletedRegistration) {
    this.blockchainId = completedRegistration.blockchainId
    this.bridgeAddressCChain = completedRegistration.bridgeAddressCChain
    this.bridgeAddressSubnet = completedRegistration.bridgeAddressSubnet
    this.id = BigNumber.from(completedRegistration.id).toString()
    this.rpcUrl = completedRegistration.rpcUrl
    let wsUrl = completedRegistration.rpcUrl.replace('https', 'wss')
    this.wsUrl = wsUrl.replace('/rpc/', '/ws/')
    this.subnetId = completedRegistration.subnetId
    this.tokenAddressCChain = completedRegistration.tokenAddressCChain
    this.vmId = completedRegistration.vmId
    this.wrappedTokenAddressSubnet =
      completedRegistration.wrappedTokenAddressSubnet
  }
}

export { CompletedRegistration }
