export const SUPPORTED_THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
}

// V1
// export const REGISTRAR_CONTRACT = '0x4834fe0d2fe50ebf14fe3596018e3713909b4f53'
// export const REGISTRAR_CONTRACT = '0xbf7d41cb6218c6fb3ddbb5beee5d8be9eaad633a'

// Testnet v1
// export const REGISTRAR_CONTRACT = '0x3b0e38c8c833636d5fc21a193f3f8aed0af36484'
// Testnet v2
// export const REGISTRAR_CONTRACT = '0x4d8e45727fba512e36208f0e626470dc8f228203'
// Testnet v3
export const REGISTRAR_CONTRACT = '0xfb3223b83a353d4d67779a79cc27725b7eb390a4'
export const COMPLETED_TOPIC =
  '0xd4a3dd47b1877dd9d3f7795f3d0e313a78d7425d91ca23251d126982e54bba17'

export const RAINBOW_KITTIES = 89428
export const AVALANCHE_TESTNET = 43113
export const AVALANCHE_MAINNET = 43114
export const ETHEREUM_MAINNET = 1

export const SUPPORTED_CHAIN = {
  [RAINBOW_KITTIES]: 'https://eu-2.saferpad.com/ava-test',
  [AVALANCHE_TESTNET]: 'https://test.snowgenesis.com/C/',
  [AVALANCHE_MAINNET]: 'https://api.avax.network/ext/bc/C/rpc',
  [ETHEREUM_MAINNET]: 'https://mainnet.infura.io/v3/',
}

export const AVALANCHE_MAINNET_NETWORK = {
  chainId: '0xa86a',
  chainName: 'Avalanche Mainnet',
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  blockExplorerUrls: ['https://snowtrace.io/'],
}

export const CHAIN_LABELS: {
  [key: number]: string
} = {
  [RAINBOW_KITTIES]: 'Rainbow Kitties',
  [AVALANCHE_TESTNET]: 'Avalanche Fuji',
  [AVALANCHE_MAINNET]: 'Avalanche Mainnet',
}
