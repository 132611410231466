import React, { ChangeEvent, useEffect } from 'react'
import { BigNumber } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'

import { Navigation } from '../components/Navigation'
import { Container } from '../components/Container'
import { FormContainer } from '../components/FormContainer'
import { Title } from '../components/Title'
import { Registrar } from '../apis/registrarContract'
import { numberWithCommas, stripCommaAndPeriod } from '../utils'
import LoadingButton from '@mui/lab/LoadingButton'
import { PageContainer } from '../components/PageContainer'
import { TextField } from '../components/TextField'
import { useSnackbar } from '../contexts/SnackbarProvider'

interface IFeeConfig {
  gasLimit: number
  // Minimum base fee of transactions. It is also the initial base fee for EIP-1559 blocks.
  // E.g. 25000000000 / 1e9 = 25
  minBaseFee: number
  // The target gas consumption of blocks. If the network starts producing blocks with gas cost
  // higher than this, base fees are increased accordingly.
  targetGas: number
  // The amount the base fee can change between blocks.
  baseFeeChangeDenominator: number
  // Minimum gas cost a block should cover.
  minBlockGasCost: number
  // Maximum gas cost a block should cover.
  maxBlockGasCost: number
  // The targeted block rate that network should produce blocks. If the network starts
  // producing faster than this, base fees are increased accordingly.
  targetBlockRate: number
  // The block gas cost change step between blocks.
  blockGasCostStep: number
  // With this enabled, your validators can specify their addresses to collect fees.
  // They need to update their chain config with the following:
  // {
  //   "feeRecipient": "<YOUR 0x-ADDRESS>"
  // }
  allowFeeRecipients: boolean
}

interface IFeeConfiguration {
  name: feeConfigType
  label: string
}

const feeConfigurations: IFeeConfiguration[] = [
  {
    name: 'gasLimit',
    label: 'Gas Limit',
  },
  {
    name: 'minBaseFee',
    label: 'Minimum Base Fee',
  },
  {
    name: 'targetGas',
    label: 'Target Gas',
  },
  {
    name: 'baseFeeChangeDenominator',
    label: 'Base Fee Change Denominator',
  },
  {
    name: 'minBlockGasCost',
    label: 'Minimum Block Gas Cost',
  },
  {
    name: 'maxBlockGasCost',
    label: 'Maximum Block Gas Cost',
  },
  {
    name: 'targetBlockRate',
    label: 'Target Block Rate',
  },
  {
    name: 'blockGasCostStep',
    label: 'Block Gas Cost Step',
  },
]

type feeConfigType =
  | 'gasLimit'
  | 'minBaseFee'
  | 'targetGas'
  | 'baseFeeChangeDenominator'
  | 'minBlockGasCost'
  | 'maxBlockGasCost'
  | 'targetBlockRate'
  | 'blockGasCostStep'

export interface IBlockChainConfig {
  chainId: string
  blockChainName: string
  totalSupply: string
  symbol: string
}

const handleBlockChainConfigChange =
  (values: State, setValues: Function) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = {
      ...values,
    }
    if (e.target.name === 'chainId') {
      newValues.blockChainConfig.chainId = e.target.value
    } else if (e.target.name === 'blockChainName') {
      newValues.blockChainConfig.blockChainName = e.target.value
    } else if (e.target.name === 'totalSupply') {
      newValues.blockChainConfig.totalSupply = numberWithCommas(e.target.value)
    } else if (e.target.name === 'symbol') {
      newValues.blockChainConfig.symbol = e.target.value
    }
    setValues(newValues)
  }

const handleFeeConfigChange =
  (values: State, setValues: Function) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = {
      ...values,
    }
    const name = e.target.name as feeConfigType
    newValues.feeConfig[name] = +e.target.value
    setValues(newValues)
  }

export interface State {
  enableBridge: boolean
  feeConfig: IFeeConfig
  blockChainConfig: IBlockChainConfig
}

const Subnets = () => {
  const { snackbarProps, setSnackbarProps } = useSnackbar()
  const { active, account, library } = useWeb3React()

  const [loading, setLoading] = React.useState(false)
  const [registrar, setRegistrar] = React.useState<Registrar>()
  const [values, setValues] = React.useState<State>({
    blockChainConfig: {
      chainId: String(Math.floor(Math.random() * 10000) + 89999),
      blockChainName: '',
      totalSupply: '300,000,000',
      symbol: '',
    },
    enableBridge: true,
    feeConfig: {
      gasLimit: 8000000,
      targetBlockRate: 2,
      minBaseFee: 25000000000,
      targetGas: 15000000,
      baseFeeChangeDenominator: 36,
      minBlockGasCost: 0,
      maxBlockGasCost: 1000000,
      blockGasCostStep: 200000,
      allowFeeRecipients: false,
    },
  })

  useEffect(() => {
    if (account && library) {
      const registrar = new Registrar(library.getSigner(), account)
      setRegistrar(registrar)
    }
  }, [account, active, library])

  return (
    <Navigation>
      <PageContainer>
        <Container>
          <Title
            title={'Create a Subnet, a Blockchain, and a Bridge'}
            subtitle={
              'Once the transaction is confirmed, it will take a few minutes before owning your personal blockchain.'
            }
            extraMargin
          />
          <Container>
            <Title
              title={'Blockchain Configuration'}
              subtitle={
                'This section will allow you to configure your blockchain settings.'
              }
            />
            <FormContainer>
              <TextField
                id="chainId"
                label="Chain ID"
                type="number"
                value={values.blockChainConfig.chainId}
                values={values}
                setValues={setValues}
                handleChange={handleBlockChainConfigChange}
              />
              <TextField
                id="blockChainName"
                label="Block Chain Name"
                value={values.blockChainConfig.blockChainName}
                values={values}
                setValues={setValues}
                handleChange={handleBlockChainConfigChange}
              />
            </FormContainer>
            <FormContainer>
              <TextField
                id="totalSupply"
                label="Total Supply"
                value={values.blockChainConfig.totalSupply}
                values={values}
                setValues={setValues}
                handleChange={handleBlockChainConfigChange}
                helperText={
                  'We will append decimal of 18 for you. For example, if you want to put' +
                  ' 1 million total supply, simply put 1,000,000'
                }
              />
            </FormContainer>
            <FormContainer>
              <TextField
                id="symbol"
                label="Token Symbol"
                value={values.blockChainConfig.symbol}
                values={values}
                setValues={setValues}
                handleChange={handleBlockChainConfigChange}
                helperText={
                  'We will create an unique rpc for your blockchain and subnet based on your token symbol.'
                }
              />
            </FormContainer>
          </Container>

          <Container>
            <Title title={'Bridge Configuration and Allocation'} />
            <FormContainer>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enableBridge}
                      onClick={(e) => {
                        setValues({
                          ...values,
                          enableBridge: !values.enableBridge,
                        })
                      }}
                    />
                  }
                  label="Enable Bridge"
                />
                <FormHelperText>
                  When bridge is enabled, 10% of the allocation will be
                  allocated to fund the bridge. The rest of the fund will be
                  allocated to your metamask address.
                </FormHelperText>
              </FormControl>
            </FormContainer>
            <FormContainer>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel htmlFor={`address`}>Address</InputLabel>
                <OutlinedInput
                  disabled
                  label="Address"
                  id={`address`}
                  name="address"
                  value={account || ''}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel htmlFor={`percent`}>Percent</InputLabel>
                <OutlinedInput
                  disabled
                  label="Percent"
                  name="percent"
                  id={`percent`}
                  value={values.enableBridge ? 90 : 100}
                />
              </FormControl>
            </FormContainer>
          </Container>

          <Container>
            <Title
              title={'Default Fee Configuration'}
              subtitle={
                'Below is the default configuration. Our alpha version does not currently support fee configuration.'
              }
            />
            {feeConfigurations.map((el: IFeeConfiguration) => {
              return (
                <FormContainer key={el.name}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel htmlFor={el.name}>{el.label}</InputLabel>
                    <OutlinedInput
                      disabled
                      type="number"
                      label={el.label}
                      name={el.name}
                      id={el.name}
                      value={values.feeConfig[el.name]}
                      onChange={handleFeeConfigChange(values, setValues)}
                    />
                  </FormControl>
                </FormContainer>
              )
            })}
          </Container>

          <LoadingButton
            disabled={!active}
            variant="contained"
            fullWidth
            loading={loading}
            sx={{ mt: 2 }}
            onClick={async (e) => {
              // TODO: Validating the inputs before sending the requests
              setLoading(true)
              try {
                const response = await registrar?.createSubnet(
                  values.blockChainConfig.blockChainName,
                  values.blockChainConfig.symbol,
                  BigNumber.from(
                    stripCommaAndPeriod(values.blockChainConfig.totalSupply)
                  ).toHexString(),
                  BigNumber.from(values.blockChainConfig.chainId).toHexString(),
                  values.enableBridge,
                  values.enableBridge ? 10 : 0
                )
                setSnackbarProps({
                  ...snackbarProps,
                  open: true,
                  message: `Transaction Succeeded: ${response.transactionHash}. Go to Profile page to view the information on your subnet and blockchain!`,
                  severity: 'success',
                })
              } catch (err: any) {
                setSnackbarProps({
                  ...snackbarProps,
                  open: true,
                  severity: 'error',
                  message: err.code === -32603 ? err.data.message : err.message,
                })
              } finally {
                setLoading(false)
              }
            }}
          >
            Create Subnet and Blockchain
          </LoadingButton>
        </Container>
      </PageContainer>
    </Navigation>
  )
}

export default Subnets
