import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import './index.css'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import App from './App'
import { SnackbarContextProvider } from './contexts/SnackbarProvider'

let theme = createTheme({
  palette: {
    mode: 'light',
  },
})
theme = responsiveFontSizes(theme)

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 1
  return library
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={theme}>
        <SnackbarContextProvider>
          <CssBaseline />
          <App />
        </SnackbarContextProvider>
      </ThemeProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
