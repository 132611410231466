import React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const FormContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flexWrap: matches ? 'nowrap' : 'wrap',
        '& > .MuiFormControl-root': {
          margin: matches ? '1rem 1rem 1rem' : '1rem 0',
        },
      }}
    >
      {children}
    </Box>
  )
}

export { FormContainer }
